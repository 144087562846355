import React from "react";
import { ErrorAlert } from "../../components/Alert";

const ErrorScreen = ({ error }) => (
  <div className="flex items-center justify-center h-screen">
    <ErrorAlert message={error} setShowError={() => {}} />
  </div>
);

export default ErrorScreen;
