import React from "react";
import { Search, ChevronLeft, ChevronRight, PlusCircle } from "lucide-react";

const DocumentsSidebar = ({
  documents,
  selectedDocument,
  setSelectedDocument,
  searchTerm,
  setSearchTerm,
  handleCreate,
  isSidebarCollapsed,
  toggleSidebar,
  isMobile,
}) => {
  return (
    <>
      <div
        className={`bg-zinc-900 border border-zinc-700 rounded-lg mr-2 overflow-hidden flex flex-col transition-all duration-300 ease-in-out 
          ${
            isMobile
              ? `fixed inset-y-0 left-0 z-50 ${
                  isSidebarCollapsed ? "-translate-x-full" : "translate-x-0"
                }`
              : isSidebarCollapsed
              ? "w-16"
              : "w-72"
          }`}
        style={{ width: isMobile ? "90%" : "auto" }}
      >
        <div className="flex items-center justify-between p-4 border-b border-zinc-700">
          <button
            onClick={toggleSidebar}
            className="p-2 hover:bg-zinc-700 bg-zinc-800 rounded-lg transition-colors duration-200 focus:outline-none focus:ring-1 focus:ring-accent-indigo"
          >
            {isSidebarCollapsed ? (
              <ChevronRight className="w-5 h-5 text-zinc-400" />
            ) : (
              <ChevronLeft className="w-5 h-5 text-zinc-400" />
            )}
          </button>
          {!isSidebarCollapsed && (
            <span className="text-zinc-100 font-semibold text-lg ml-2">
              Documents
            </span>
          )}
        </div>
        {!isSidebarCollapsed && (
          <>
            <div className="p-4 border-b border-zinc-700">
              <div className="relative mb-2">
                <input
                  type="text"
                  placeholder="Search documents..."
                  className="w-full pl-10 pr-4 py-2 text-sm rounded-lg bg-zinc-800 text-zinc-100 placeholder-zinc-400 focus:outline-none focus:ring-2 focus:ring-accent-indigo border border-zinc-600 transition-all duration-200"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
                <Search className="absolute left-3 top-2.5 text-zinc-400 w-4 h-4" />
              </div>
              <button
                onClick={handleCreate}
                className="w-full flex items-center justify-center p-2 bg-accent-indigo hover:bg-accent-indigo/80 text-zinc-100 rounded-lg transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-accent-indigo"
              >
                <PlusCircle className="w-4 h-4 mr-2" />
                <span>Create New Document</span>
              </button>
            </div>
            <div className="flex-1 overflow-y-auto py-2">
              {documents.map((doc) => (
                <div
                  key={doc.id}
                  className={`p-3 mx-2 my-1 rounded-lg cursor-pointer transition-all duration-200 ${
                    selectedDocument?.id === doc.id
                      ? "bg-accent-indigo text-zinc-100"
                      : "hover:bg-zinc-800 text-zinc-300"
                  }`}
                  onClick={() => setSelectedDocument(doc)}
                >
                  <span className="font-medium text-sm">{doc.title}</span>
                </div>
              ))}
            </div>
          </>
        )}
      </div>
      {isMobile && !isSidebarCollapsed && (
        <div
          className="fixed inset-0 bg-zinc-900 bg-opacity-50 z-40"
          onClick={toggleSidebar}
        ></div>
      )}
    </>
  );
};

export default DocumentsSidebar;
