import { useEffect } from "react";

const useCookiebot = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.id = "Cookiebot";
    script.src = "https://consent.cookiebot.com/uc.js";
    script.setAttribute("data-cbid", "9eb68031-0cb2-44d8-bd97-96ee0a0df0d9");
    script.setAttribute("data-blockingmode", "auto");
    script.type = "text/javascript";

    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, []);
};

export default useCookiebot;
