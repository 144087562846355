import React from "react";
import Card from "./Card";

function CardGroup({ favorites }) {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
      <Card
        title="Favorites"
        description="Your favorite decks"
        items={favorites}
        route="/practice"
      />
      <Card
        title="Last Used"
        description="Your recently used decks"
        items={JSON.parse(localStorage.getItem("lastUsedDecks")) ?? []}
        route="/topics"
      />
      {/* Uncomment and adjust as needed
      <Card
        title="Suggestions"
        description="Recommended topics"
        items={["Behavioral Finance", "Computer Science", "Economics"]}
        route="/topics"
      />
      */}
    </div>
  );
}

export default CardGroup;
