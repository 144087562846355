import React from "react";
import { Link } from "react-router-dom";
import CheckoutButton from "./CheckoutButton";

const MembershipBadge = ({ membership, user }) => {
  console.log("membership", membership);
  const baseClasses =
    "font-medium text-white mx-auto mb-2 text-xs sm:text-sm " +
    "inline-block px-3 py-1 rounded-full shadow-md " +
    "transform hover:scale-105 transition-all duration-300 " +
    "uppercase tracking-wide";

  const membershipStyles = {
    free: "bg-gradient-to-r from-gray-600 to-gray-700 text-gray-100",
    plus: "bg-gradient-to-r from-indigo-600 to-purple-600 text-white",
    pro: "bg-gradient-to-r from-purple-600 to-pink-500 text-white",
  };

  const style =
    membershipStyles[membership?.toLowerCase()] || membershipStyles.free;

  const upgradeLink =
    membership?.toLowerCase() === "free"
      ? "/subscription"
      : membership?.toLowerCase() === "plus"
      ? "/subscription"
      : null;

  return (
    <div className="flex flex-col items-center space-y-2">
      <p className={`${baseClasses} ${style}`}>
        {membership === "free" ? "trial" : membership}
      </p>
      {/* {upgradeLink && (
        <Link
          to={upgradeLink}
          className="text-sm text-indigo-400 hover:text-indigo-300 transition-colors duration-300"
        >
          Upgrade
        </Link>
      )} */}
    </div>
  );
};

export default MembershipBadge;
