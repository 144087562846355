import React, { useState, useEffect } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { supabase } from "./supabaseClient";
import { DecksProvider } from "./services/DeckProvider";
import LandingPage from "./pages/landingpage";
import Dashboard from "./pages/Dashboard.js/Dashboard";
import Navbar from "./components/Navbar/Navbar";
import ProtectedRoute from "./components/ProtectedRoute";
import PracticePage from "./pages/PracticePage";
import DecksPage from "./pages/DecksPage";
import Notification from "./components/Notification";
import { LoadingIndicator } from "./components/LoadingAnimations";
import Explore from "./pages/Explore";
import Impressum from "./pages/Impressum";
import Datenschutz from "./pages/Datenschutz";
import Nutzungsbedingungen from "./pages/Nutzungsbedingungen";
import SettingsPage from "./pages/Settings/SettingsPage";
import SubscriptionPlans from "./pages/SubscripitonPlans";
import ErrorBoundary from "./components/ErrorBoundary";
import LoginPage from "./components/LoginInterface";
import AthenaSEO from "./AthenaSEO";
import useCookiebot from "./components/UseCookieBot";
import DocumentPage from "./pages/Documents/DocumentPage";
const RedirectRoute = ({ session, children }) => {
  if (session) {
    return <Navigate to="/dashboard" />;
  }
  return children;
};

function App() {
  const [session, setSession] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const [mainContentPadding, setMainContentPadding] = useState("");
  useCookiebot();

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session);
      setIsLoading(false);
    });

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
    });

    return () => subscription.unsubscribe();
  }, []);

  if (isLoading) {
    return <LoadingIndicator />;
  }

  return (
    <DecksProvider>
      <ErrorBoundary>
        <AthenaSEO />
        <div className="flex min-h-screen bg-background">
          <div className="flex-1 flex flex-col">
            <Navbar
              session={session}
              setMainContentPadding={setMainContentPadding}
            />
            <div
              className={`transition-all duration-300 ${mainContentPadding}`}
            >
              <Notification />
              <div
                className={session ? "bg-zinc-800 rounded-lg m-2 w-full" : null}
              >
                <Routes>
                  <Route
                    path="/"
                    element={
                      <RedirectRoute session={session}>
                        <LandingPage />
                      </RedirectRoute>
                    }
                  />
                  <Route
                    path="/login"
                    element={
                      <RedirectRoute session={session}>
                        <LoginPage
                          session={session}
                          setSession={setSession}
                          supabase={supabase}
                        />
                      </RedirectRoute>
                    }
                  />
                  <Route
                    path="/dashboard"
                    element={
                      <ProtectedRoute session={session}>
                        <Dashboard session={session} supabase={supabase} />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/practice"
                    element={
                      <ProtectedRoute session={session}>
                        <PracticePage session={session} />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/decks"
                    element={
                      <ProtectedRoute session={session}>
                        <DecksPage session={session} />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/documents"
                    element={
                      <ProtectedRoute session={session}>
                        <DocumentPage session={session} />
                      </ProtectedRoute>
                    }
                  />

                  <Route
                    path="/explore"
                    element={
                      <ProtectedRoute session={session}>
                        <Explore session={session} />
                      </ProtectedRoute>
                    }
                  />
                  <Route path="/impressum" element={<Impressum />} />
                  <Route path="/privacy-policy" element={<Datenschutz />} />
                  <Route
                    path="/terms-of-service"
                    element={<Nutzungsbedingungen />}
                  />
                  <Route
                    path="/settings"
                    element={
                      <ProtectedRoute session={session}>
                        <SettingsPage session={session} />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/subscription"
                    element={<SubscriptionPlans session={session} />}
                  />
                </Routes>
              </div>
            </div>
          </div>
        </div>
      </ErrorBoundary>
    </DecksProvider>
  );
}

export default App;
