import React from "react";

const MobileOverlay = ({
  isDecksSidebarCollapsed,
  isChatSidebarOpen,
  setIsDecksSidebarCollapsed,
  setIsChatSidebarOpen,
}) => {
  return (
    (!isDecksSidebarCollapsed || isChatSidebarOpen) && (
      <div
        className="md:hidden fixed inset-0 bg-black bg-opacity-50 z-20"
        onClick={() => {
          setIsDecksSidebarCollapsed(true);
          setIsChatSidebarOpen(false);
        }}
      ></div>
    )
  );
};

export default MobileOverlay;
