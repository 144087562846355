import React, { useState, useEffect } from "react";
import { Search, Edit3, Sparkles, AlertCircle } from "lucide-react";
import CardGrid from "../../components/Decks/CardGrid";
import { NoDecksAvailable } from "../../components/NoCards";
import FlashcardGenerator from "../../components/GenerateCards/FlashCardGenerator";
import Toolbar from "../../components/Decks/Toolbar";
import GeneratedPageModal from "./GeneratedPageModal";

const CustomAlert = ({ message }) => (
  <div className="bg-zinc-800 border-l-4 border-red-500 text-zinc-100 p-4 rounded-md mb-4 flex items-center shadow-md">
    <AlertCircle className="h-5 w-5 mr-3 text-red-500 flex-shrink-0" />
    <span className="text-sm">{message}</span>
  </div>
);

const TabSystem = ({
  decks,
  cardSearchTerm,
  setCardSearchTerm,
  filteredCards,
  setSelectedCard,
  setIsCardDetailOpen,
  setShowCreateDeckModal,
  selectedDeck,
  setSelectedDeck,
  onSave,
  onFavorite,
  onDeleteDeck,
  setIsEditing,
  session,
}) => {
  const [activeTab, setActiveTab] = useState("edit");
  const [availableFlashcards, setAvailableFlashcards] = useState([]);
  const [addedFlashcards, setAddedFlashcards] = useState([]);
  const [showWarning, setShowWarning] = useState(false);
  const [isGenerating, setIsGenerating] = useState(false);
  const [generatedPage, setGeneratedPage] = useState(null);
  const api_host = process.env.REACT_APP_API_HOST;
  const [isModalOpen, setIsModalOpen] = useState(false);

  const page_schema = {
    title: "Page",
    description: "Represents a page entity",
    type: "object",
    properties: {
      title: {
        type: "string",
        description: "The title of the page",
      },
      content: {
        type: "string",
        description:
          "The entire content of the essay page, including sections and markdown formatting",
      },
      tags: {
        type: "array",
        items: {
          type: "string",
        },
        description: "List of tags associated with the page",
      },
    },
    required: ["content"],
  };

  const page_example = {
    title: "Introduction",
    content:
      "# Introduction\nThis is a brief introduction to the topic.\n\n## Key Points\n* Point 1\n* Point 2\n* Point 3",
    tags: ["introduction", "key points"],
  };

  const tabs = [
    { id: "edit", label: "Edit Deck", icon: Edit3 },
    { id: "generate", label: "Generate Cards", icon: Sparkles },
  ];

  useEffect(() => {
    setAvailableFlashcards([]);
    setAddedFlashcards([]);
  }, [selectedDeck?.id]);

  const handleTabClick = (tabId) => {
    if (tabId === "generate") {
      if (!selectedDeck) {
        setShowWarning(true);
        setTimeout(() => setShowWarning(false), 5000);
      } else {
        setActiveTab(tabId);
        setShowWarning(false);
      }
    } else {
      setActiveTab(tabId);
      setShowWarning(false);
    }
  };

  const onGeneratePage = async () => {
    if (!selectedDeck) {
      setShowWarning(true);
      setTimeout(() => setShowWarning(false), 5000);
      return;
    }
    setIsGenerating(true);
    setIsModalOpen(true); // Open the modal immediately
    setGeneratedPage(null); // Reset the generated page
    try {
      const response = await fetch(`${api_host}/api/create-page`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          prompt: ` Here is the deck of cards:  ${JSON.stringify(
            selectedDeck.cards
          )}`,
          system_prompt: "You are a professional writer,",
          preprompt: ` You are a professional writer and you are experienced in adhering to writing instructions for outstanding quality writing output. Generate an entire and coherent essay page in markdown format based on the given deck cards in json format. Use this JSON schema to structure the page: ${JSON.stringify(
            page_schema
          )}, Here is an json example: ${JSON.stringify(page_example)}`,
        }),
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      let data = await response.json();
      data = JSON.parse(data.response);
      console.log("Generated page:", data);
      setGeneratedPage(data);
    } catch (error) {
      console.error("Error generating page:", error);
      // You might want to show an error message to the user here
    } finally {
      setIsGenerating(false);
    }
  };

  const handleSave = async () => {
    try {
      const saveResponse = await fetch(`${api_host}/api/save-page`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          user_id: session.user.id, // Implement this function to get the current user's ID
          content: generatedPage["content"],
          deck_id: selectedDeck.id,
          title: generatedPage["title"],
          tags: selectedDeck.tags || [],
        }),
      });

      if (!saveResponse.ok) {
        throw new Error("Failed to save the page");
      }

      const saveResult = await saveResponse.json();
      console.log("Page saved:", saveResult);
    } catch (error) {
      console.error("Error saving page:", error);
      // You might want to show an error message to the user here
    }
  };

  return (
    <div className="w-full flex flex-col h-full">
      <div className="flex border-b border-zinc-700">
        <GeneratedPageModal
          isOpen={isModalOpen}
          setIsOpen={setIsModalOpen}
          page={generatedPage}
          onSave={handleSave}
          isLoading={isGenerating}
        />
        {tabs.map((tab) => {
          const Icon = tab.icon;
          return (
            <button
              key={tab.id}
              onClick={() => handleTabClick(tab.id)}
              className={`px-4 py-2 font-medium text-sm focus:outline-none flex items-center ${
                activeTab === tab.id
                  ? "text-blue-500 border-b-2 border-blue-500"
                  : "text-zinc-400 hover:text-zinc-100"
              }`}
            >
              <Icon className="w-4 h-4 mr-2" />
              {tab.label}
            </button>
          );
        })}
      </div>
      {showWarning && (
        <CustomAlert message="Please create or select a deck before generating cards." />
      )}
      <div className="flex-1 overflow-hidden">
        {activeTab === "edit" && (
          <div className="h-full overflow-y-auto">
            {decks?.length > 0 ? (
              <div className="flex flex-col h-full">
                <div className="flex items-center justify-between p-2 bg-zinc-800">
                  <Toolbar
                    selectedDeck={selectedDeck}
                    onSave={onSave}
                    onFavorite={onFavorite}
                    onDeleteDeck={onDeleteDeck}
                    setIsEditing={setIsEditing}
                    setShowCreateDeckModal={setShowCreateDeckModal}
                    decks={decks}
                    onGeneratePage={onGeneratePage}
                    isGenerating={isGenerating}
                  />
                  <div className="relative w-64">
                    <input
                      type="text"
                      placeholder="Search cards..."
                      className="w-full pl-10 pr-4 py-2 text-sm rounded-lg bg-zinc-700 text-zinc-100 placeholder-zinc-400 focus:outline-none focus:ring-2 focus:ring-accent-indigo border border-zinc-600 transition-colors duration-200"
                      value={cardSearchTerm}
                      onChange={(e) => setCardSearchTerm(e.target.value)}
                    />
                    <Search className="absolute left-3 top-2.5 text-zinc-400 w-4 h-4" />
                  </div>
                </div>
                <div className="flex-1 overflow-y-auto mt-4">
                  <CardGrid
                    filteredCards={filteredCards}
                    setSelectedCard={setSelectedCard}
                    setIsCardDetailOpen={setIsCardDetailOpen}
                  />
                </div>
              </div>
            ) : (
              <NoDecksAvailable
                setShowCreateDeckModal={setShowCreateDeckModal}
              />
            )}
          </div>
        )}
        {activeTab === "generate" && (
          <div className="h-full overflow-y-auto">
            <FlashcardGenerator
              selectedDeck={selectedDeck}
              setSelectedDeck={setSelectedDeck}
              availableFlashcards={availableFlashcards}
              setAvailableFlashcards={setAvailableFlashcards}
              addedFlashcards={addedFlashcards}
              setAddedFlashcards={setAddedFlashcards}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default TabSystem;
