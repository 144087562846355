import { fetchDecks } from "../services/apiCalls";

export const refreshDecks = async (setDecks, user_id, access_token) => {
  const decks = await fetchDecks(user_id, access_token);
  setDecks(decks);
  return decks;
};

export const handleDeckClick = (id, name, setLastUsedDecks) => {
  if (!id || !name) {
    return;
  }
  setLastUsedDecks((prevDecks) => {
    // Create a new item object
    const newDeck = { id, name };

    // Add the new item at the beginning of the array
    const updatedDecks = [
      newDeck,
      ...prevDecks.filter((deck) => deck.id !== id),
    ];

    // Ensure the array does not exceed 5 items
    if (updatedDecks.length > 5) {
      updatedDecks.pop();
    }

    // Save the updated array to localStorage
    localStorage.setItem("lastUsedDecks", JSON.stringify(updatedDecks));
    return updatedDecks;
  });
};

export const findLastUsedDeck = (decks, lastUsedDeck) => {
  if (!decks || !lastUsedDeck) {
    return null;
  }
  return decks.find((deck) => deck.id === lastUsedDeck.id);
};
