import React from "react";

const Impressum = () => {
  return (
    <div className="bg-gradient-to-r from-zinc-800 via-gray-900 to-black text-white py-16 sm:py-32">
      <div className="container mx-auto px-6 lg:px-8">
        <h1 className="text-4xl font-bold mb-6 text-center">Impressum</h1>
        <div className="bg-gray-800 p-8 rounded-lg shadow-md">
          <p className="mb-4">Angaben gemäß § 5 TMG</p>
          <p className="mb-4">
            Chams Toure
            <br />
            Ernst-Barlach-Straße 4<br />
            23843 Bad Oldesloe
            <br />
            Deutschland
          </p>
          <h2 className="text-2xl font-semibold mt-6 mb-2">Vertreten durch:</h2>
          <p className="mb-4">Chams Toure</p>
          <h2 className="text-2xl font-semibold mt-6 mb-2">Kontakt:</h2>
          <p className="mb-4">
            Telefon: +49 (0) 17620708325
            <br />
            E-Mail: chams.toure@gmail.com
          </p>
          <h2 className="text-2xl font-semibold mt-6 mb-2">Umsatzsteuer-ID:</h2>
          <h2 className="text-2xl font-semibold mt-6 mb-2">
            Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV:
          </h2>
          <p className="mb-4">
            Chams Toure
            <br />
            Ernst-Barlach-Straße 4<br />
            23843 Bad Oldesloe
          </p>
          <h2 className="text-2xl font-semibold mt-6 mb-2">
            Haftungsausschluss:
          </h2>
          <h3 className="text-xl font-semibold mt-4 mb-2">
            Haftung für Inhalte
          </h3>
          <p className="mb-4">
            Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte
            auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach
            §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht
            verpflichtet, übermittelte oder gespeicherte fremde Informationen zu
            überwachen oder nach Umständen zu forschen, die auf eine
            rechtswidrige Tätigkeit hinweisen.
          </p>
          <p className="mb-4">
            Verpflichtungen zur Entfernung oder Sperrung der Nutzung von
            Informationen nach den allgemeinen Gesetzen bleiben hiervon
            unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem
            Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei
            Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese
            Inhalte umgehend entfernen.
          </p>
          <h3 className="text-xl font-semibold mt-4 mb-2">Haftung für Links</h3>
          <p className="mb-4">
            Unser Angebot enthält Links zu externen Websites Dritter, auf deren
            Inhalte wir keinen Einfluss haben. Deshalb können wir für diese
            fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der
            verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber
            der Seiten verantwortlich. Die verlinkten Seiten wurden zum
            Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft.
            Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht
            erkennbar.
          </p>
          <p className="mb-4">
            Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist
            jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht
            zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir
            derartige Links umgehend entfernen.
          </p>
          <h3 className="text-xl font-semibold mt-4 mb-2">Urheberrecht</h3>
          <p className="mb-4">
            Die durch die Seitenbetreiber erstellten Inhalte und Werke auf
            diesen Seiten unterliegen dem deutschen Urheberrecht. Beiträge
            Dritter sind als solche gekennzeichnet. Die Vervielfältigung,
            Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der
            Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des
            jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite
            sind nur für den privaten, nicht kommerziellen Gebrauch gestattet.
          </p>
          <p className="mb-4">
            Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt
            wurden, werden die Urheberrechte Dritter beachtet. Insbesondere
            werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie
            trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten
            wir um einen entsprechenden Hinweis. Bei Bekanntwerden von
            Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Impressum;
