import React from "react";
import { Search, ChevronLeft, ChevronRight, PlusCircle } from "lucide-react";

const DecksSidebar = ({
  showCreateDeckModal,
  setShowCreateDeckModal,
  isDecksSidebarCollapsed,
  isMobile,
  deckSearchTerm,
  setDeckSearchTerm,
  filteredDecks,
  selectedDeck,
  setSelectedDeck,
  toggleDecksSidebar,
}) => {
  return (
    <>
      <div
        className={`bg-zinc-900 border border-zinc-700 rounded-lg mr-2 overflow-hidden flex flex-col transition-all duration-300 ease-in-out 
          ${
            isMobile
              ? `fixed inset-y-0 left-0 z-50 ${
                  isDecksSidebarCollapsed
                    ? "-translate-x-full"
                    : "translate-x-0"
                }`
              : isDecksSidebarCollapsed
              ? "w-16"
              : "w-72"
          }`}
        style={{ width: isMobile ? "90%" : "auto" }}
      >
        <div className="flex items-center justify-between p-4 border-b border-zinc-700">
          <button
            onClick={toggleDecksSidebar}
            className="p-2 hover:bg-zinc-700 bg-zinc-800 rounded-lg transition-colors duration-200 focus:outline-none focus:ring-1 focus:ring-accent-indigo"
          >
            {isDecksSidebarCollapsed ? (
              <ChevronRight className="w-5 h-5 text-zinc-400" />
            ) : (
              <ChevronLeft className="w-5 h-5 text-zinc-400" />
            )}
          </button>
          {!isDecksSidebarCollapsed && (
            <span className="text-zinc-100 font-semibold text-lg ml-2">
              Decks
            </span>
          )}
        </div>
        {!isDecksSidebarCollapsed && (
          <>
            <div className="p-4 border-b border-zinc-700">
              <div className="relative mb-2">
                <input
                  type="text"
                  placeholder="Search decks..."
                  className="w-full pl-10 pr-4 py-2 text-sm rounded-lg bg-zinc-800 text-zinc-100 placeholder-zinc-400 focus:outline-none focus:ring-2 focus:ring-accent-indigo border border-zinc-600 transition-all duration-200"
                  value={deckSearchTerm}
                  onChange={(e) => setDeckSearchTerm(e.target.value)}
                />
                <Search className="absolute left-3 top-2.5 text-zinc-400 w-4 h-4" />
              </div>
              <button
                onClick={() => setShowCreateDeckModal(true)}
                className="w-full flex items-center justify-center p-2 bg-accent-indigo hover:bg-accent-indigo/80 text-zinc-100 rounded-lg transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-accent-indigo"
              >
                <PlusCircle className="w-4 h-4 mr-2" />
                <span>Create New Deck</span>
              </button>
            </div>
            <div className="flex-1 overflow-y-auto py-2">
              {filteredDecks.map((deck) => (
                <div
                  key={deck.id}
                  className={`p-3 mx-2 my-1 rounded-lg cursor-pointer transition-all duration-200 ${
                    selectedDeck?.id === deck.id
                      ? "bg-accent-indigo text-zinc-100"
                      : "hover:bg-zinc-800 text-zinc-300"
                  }`}
                  onClick={() => setSelectedDeck(deck)}
                >
                  <div className="flex justify-between items-center">
                    <span className="font-medium text-sm">{deck.name}</span>
                    <span
                      className={`text-xs px-2 py-1 rounded-full ${
                        selectedDeck?.id === deck.id
                          ? "bg-accent-indigo/50 text-zinc-100"
                          : "bg-zinc-700 text-zinc-300"
                      }`}
                    >
                      {deck.cards.length}
                    </span>
                  </div>
                </div>
              ))}
            </div>
          </>
        )}
      </div>
      {isMobile && !isDecksSidebarCollapsed && (
        <div
          className="fixed inset-0 bg-zinc-900 bg-opacity-50 z-40"
          onClick={toggleDecksSidebar}
        ></div>
      )}
    </>
  );
};

export default DecksSidebar;
