import React from "react";
import { Check, Zap, Star, Crown } from "lucide-react";
import { useNavigate } from "react-router-dom";

const PricingPlan = ({
  title,
  price,
  features,
  popular,
  loginWithRedirect,
  icon: Icon,
}) => (
  <div
    className={`bg-gray-800 bg-opacity-50 backdrop-blur-sm rounded-xl p-6 shadow-lg transition-all duration-300 hover:transform hover:scale-105 ${
      popular ? "border-2 border-purple-500 relative overflow-hidden" : ""
    }`}
  >
    {popular && (
      <div className="absolute top-0 right-0 bg-purple-500 text-white px-3 py-1 text-xs uppercase font-semibold transform rotate-45 translate-x-7 translate-y-5 shadow-md">
        Most popular
      </div>
    )}
    <div className="flex items-center justify-center mb-4">
      <Icon
        className={`w-12 h-12 ${
          popular ? "text-purple-400" : "text-indigo-400"
        }`}
      />
    </div>
    <h3 className="text-2xl font-bold text-white mb-4 text-center">{title}</h3>
    <p className="text-4xl font-bold text-white mb-6 text-center">
      ${price}
      <span className="text-xl text-gray-400">/month</span>
    </p>
    <ul className="mb-6 space-y-3">
      {features.map((feature, index) => (
        <li key={index} className="flex items-start text-gray-300">
          <Check className="w-5 h-5 mr-2 text-green-500 flex-shrink-0 mt-1" />
          <span>{feature}</span>
        </li>
      ))}
    </ul>
    <button
      onClick={() => loginWithRedirect()}
      className={`w-full py-3 px-4 rounded-full font-semibold transition duration-300 ${
        popular
          ? "bg-gradient-to-r from-purple-600 to-indigo-600 hover:from-purple-500 hover:to-indigo-500 text-white"
          : "bg-gray-700 hover:bg-gray-600 text-white"
      }`}
    >
      Start free today
    </button>
  </div>
);

const PricingSection = ({ loginWithRedirect }) => {
  const navigate = useNavigate();
  const plans = [
    {
      title: "Starter",
      price: 0,
      features: [
        "Unlimited decks",
        "Unlimited cards per deck",
        "5 PDFs per hour",
        "Standard AI model",
        "PDFs and Texts up to 20,000 words",
      ],
      icon: Zap,
    },
    {
      title: "Plus",
      price: 4.99,
      features: [
        "All Starter features",
        "Advanced AI model",
        "PDFs and Texts up to 30,000 words",
        "Early access to new features",
        "Priority email support",
      ],
      popular: true,
      icon: Star,
    },
    {
      title: "Pro",
      price: 9.99,
      features: [
        "All Plus features",
        "Most intelligent AI model",
        "Unlimited PDFs",
        "PDFs and Texts up to 100,000 words",
        "24/7 priority support",
      ],
      icon: Crown,
    },
  ];

  return (
    <section className="bg-gradient-to-b from-gray-900 to-black py-20 relative overflow-hidden">
      <div className="absolute inset-0 bg-grid-white/[0.05] bg-[length:30px_30px]" />
      <div className="container mx-auto px-4 relative z-10">
        <h2 className="text-4xl md:text-5xl font-bold text-center mb-4">
          <span className="text-transparent bg-clip-text bg-gradient-to-r from-indigo-400 via-purple-500 to-pink-500">
            Pricing Plans
          </span>
        </h2>
        <p className="text-center text-gray-300 text-xl mb-12 max-w-2xl mx-auto">
          Unlock your learning potential with Athena. Choose the plan that fits
          your journey and start mastering any topic today.
        </p>
        <div className="grid md:grid-cols-3 gap-8">
          {plans.map((plan, index) => (
            <PricingPlan
              key={index}
              {...plan}
              loginWithRedirect={loginWithRedirect}
            />
          ))}
        </div>
        <p className="text-center text-gray-400 mt-12">
          All plans include a 14-day free trial. No credit card required to
          start.
        </p>
      </div>
    </section>
  );
};

export default PricingSection;
