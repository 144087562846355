// Notification.js
import React from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Notification = () => {
  return (
    <ToastContainer position="top-center" hideProgressBar={true} theme="dark" />
  );
};

export const notifyError = (message) => {
  toast.error(message);
};

export const notifySuccess = (message) => {
  toast.success(message);
};

export default Notification;
