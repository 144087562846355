import React, { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { notifyError, notifySuccess } from "./Notification";
import { useDecks } from "../services/DeckProvider";
import SimpleModal from "./SimpleModal";
import Button from "./Button";
import { Plus } from "lucide-react";

export default function CreateDeckModal({
  isOpen,
  setIsOpen,
  setSelectedDeck,
}) {
  const [deckName, setDeckName] = useState("");
  const [tags, setTags] = useState("");
  const { refreshDecks, session } = useDecks();
  const api_host = process.env.REACT_APP_API_HOST;

  const onCreate = async () => {
    if (!deckName.trim()) {
      notifyError("Please enter a deck name");
      return;
    }
    const newDeck = {
      id: uuidv4(),
      name: deckName.trim(),
      tags: tags
        .split(",")
        .map((tag) => tag.trim())
        .filter((tag) => tag !== ""),
      cards: [],
      user_id: session.user.id,
      origin_owner_id: session.user.id,
      created_at: new Date().toISOString(),
    };
    await createDeck(newDeck);
  };

  async function createDeck(deck) {
    try {
      const response = await fetch(`${api_host}/create-deck`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
        body: JSON.stringify(deck),
      });
      const data = await response.json();
      notifySuccess("Deck was successfully created!");
      setSelectedDeck?.(data);

      // Use refreshDecks instead of fetchUserData
      await refreshDecks(data);

      setIsOpen(false);
    } catch (error) {
      notifyError("There was an error creating the deck");
    }
  }

  return (
    <SimpleModal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      title="Create a new deck"
    >
      <div className="space-y-6">
        <div className="space-y-2">
          <label
            htmlFor="deckName"
            className="block text-sm font-medium text-zinc-300"
          >
            Deck Name
          </label>
          <input
            type="text"
            id="deckName"
            value={deckName}
            onChange={(e) => setDeckName(e.target.value)}
            className="w-full px-3 py-2 text-zinc-200 bg-zinc-700 border border-zinc-600 rounded-lg focus:outline-none focus:ring-2 focus:ring-accent-indigo/50 transition-all duration-200 ease-in-out"
            placeholder="Enter deck name..."
          />
        </div>
        <div className="space-y-2">
          <label
            htmlFor="tags"
            className="block text-sm font-medium text-zinc-300"
          >
            Topics (optional)
          </label>
          <textarea
            id="tags"
            value={tags}
            onChange={(e) => setTags(e.target.value)}
            className="w-full px-3 py-2 text-zinc-200 bg-zinc-700 border border-zinc-600 rounded-lg focus:outline-none focus:ring-2 focus:ring-accent-indigo/50 transition-all duration-200 ease-in-out"
            rows="3"
            placeholder="Enter topics separated by commas..."
          />
        </div>
        <div className="flex justify-end">
          <Button
            onClick={onCreate}
            icon={Plus}
            className="bg-accent-indigo/10 text-accent-indigo hover:bg-accent-indigo/20 transition-colors duration-200"
          >
            Create Deck
          </Button>
        </div>
      </div>
    </SimpleModal>
  );
}
