import React, { useState, useRef, useEffect } from "react";
import { Plus, Minus, Search, Edit, Trash2, Wand } from "lucide-react";
import Button from "../Button";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { FaTimes } from "react-icons/fa";
import { HtmlContent } from "../../services/htmlrender";

const FlashcardEditor = ({ flashcard, onSave, onCancel }) => {
  const [question, setQuestion] = useState(flashcard.question);
  const [answer, setAnswer] = useState(flashcard.answer);
  const questionQuillRef = useRef(null);
  const answerQuillRef = useRef(null);

  useEffect(() => {
    setQuestion(flashcard.question);
    setAnswer(flashcard.answer);
  }, [flashcard]);

  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline"],
      [{ list: "ordered" }, { list: "bullet" }],
    ],
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "list",
    "bullet",
    "link",
  ];

  const handleSave = () => {
    if (!question.trim() || !answer.trim()) {
      alert("Please fill out both question and answer fields.");
      return;
    }

    onSave({
      ...flashcard,
      question: question.trim(),
      answer: answer.trim(),
    });
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center p-4 z-50 overflow-y-auto backdrop-blur-sm">
      <div className="bg-zinc-800 rounded-lg shadow-xl w-full max-w-3xl max-h-[90vh] overflow-y-auto relative">
        <button
          onClick={onCancel}
          className="absolute top-2 right-2 p-2 text-zinc-400 hover:text-zinc-200 transition-colors duration-200 bg-zinc-700 hover:bg-zinc-600 rounded-full z-10"
        >
          <FaTimes size={20} />
        </button>
        <div className="bg-zinc-700 p-4 rounded-t-lg border-b border-zinc-600">
          <h1 className="text-2xl sm:text-3xl font-bold text-zinc-100">
            Edit Flashcard
          </h1>
        </div>
        <div className="p-6">
          <div className="space-y-6">
            <div className="bg-zinc-700 p-4 rounded-lg">
              <label
                htmlFor="question"
                className="block text-lg sm:text-xl font-medium text-zinc-200 mb-2"
              >
                Question
              </label>
              <ReactQuill
                ref={questionQuillRef}
                theme="snow"
                value={question}
                onChange={setQuestion}
                modules={modules}
                formats={formats}
                placeholder="Enter card question..."
                className="bg-zinc-600 text-zinc-100 rounded-md"
              />
            </div>
            <div className="bg-zinc-700 p-4 rounded-lg">
              <label
                htmlFor="answer"
                className="block text-lg sm:text-xl font-medium text-zinc-200 mb-2"
              >
                Answer
              </label>
              <ReactQuill
                ref={answerQuillRef}
                theme="snow"
                value={answer}
                onChange={setAnswer}
                modules={modules}
                formats={formats}
                placeholder="Enter answer..."
                className="bg-zinc-600 text-zinc-100 rounded-md"
              />
            </div>
            <div className="flex flex-col sm:flex-row justify-end space-y-2 sm:space-y-0 sm:space-x-2 mt-6 pt-4 border-t border-zinc-700">
              <button
                type="button"
                className="px-4 py-2 bg-zinc-600 text-zinc-100 rounded-md hover:bg-zinc-500 focus:outline-none focus:ring-2 focus:ring-zinc-400 focus:ring-offset-2 transition duration-300 ease-in-out"
                onClick={onCancel}
              >
                Cancel
              </button>
              <button
                type="button"
                className="px-4 py-2 bg-blue-600 text-zinc-100 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition duration-300 ease-in-out"
                onClick={handleSave}
              >
                Save Changes
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Input = React.forwardRef(
  ({ className = "", type = "text", icon, error, ...props }, ref) => {
    const baseStyle =
      "w-full px-4 py-2 bg-zinc-700 text-zinc-100 rounded-lg focus:outline-none focus:ring-2 focus:ring-accent-indigo/50 border border-zinc-600 transition-all duration-200";
    const errorStyle = "border-accent-rose focus:ring-accent-rose/50";
    const iconStyle = "pl-10";

    return (
      <div className="relative">
        {icon && (
          <span className="absolute left-3 top-1/2 transform -translate-y-1/2 text-zinc-400">
            {icon}
          </span>
        )}
        <input
          ref={ref}
          type={type}
          className={`
          ${baseStyle}
          ${error ? errorStyle : ""}
          ${icon ? iconStyle : ""}
          ${className}
        `}
          {...props}
        />
        {error && <p className="mt-1 text-sm text-accent-rose">{error}</p>}
      </div>
    );
  }
);

Input.displayName = "Input";

const FlashcardDisplay = ({
  availableFlashcards,
  addedFlashcards,
  onAddFlashcard,
  setAddedFlashcards,
  setAvailableFlashcards,
  onRemoveFlashcard,
  onEditFlashcard,
  onDeleteFlashcard,
  api_host,
  access_token,
}) => {
  const [activeTab, setActiveTab] = useState("available");
  const [searchTerm, setSearchTerm] = useState("");
  const [editingCard, setEditingCard] = useState(null);
  const [improvingCard, setImprovingCard] = useState(null);

  const handleSaveEdit = (editedCard) => {
    onEditFlashcard(editedCard);
    setEditingCard(null);
  };

  const improveFlashcard = async (flashcard) => {
    const url = `${api_host}/api/improve-card`;
    const prompt = `Please improve the following flashcard:
      Question: ${flashcard.question}
      Answer: ${flashcard.answer}
      
      Provide a more comprehensive and better answer, and if necessary, refine the question for clarity, but please dont make it not unneccessary long but also not too short, especially the answer. It is a flashcard in the first place.`;

    const header = {
      method: "POST",
      body: JSON.stringify({ prompt }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${access_token}`,
      },
      redirect: "follow",
    };

    try {
      const response = await fetch(url, header);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      const result = JSON.parse(data);

      return result;
    } catch (error) {
      console.error("Error improving flashcard:", error);
      throw error;
    }
  };

  const handleMakeBetter = async (flashcard) => {
    setImprovingCard(flashcard);
    try {
      const improvedCard = await improveFlashcard(flashcard);

      // Determine which array the flashcard is in
      const isInAvailable = availableFlashcards.some(
        (card) => card.id === flashcard.id
      );

      if (isInAvailable) {
        setAvailableFlashcards((prevCards) =>
          prevCards.map((card) =>
            card.id === flashcard.id
              ? { ...improvedCard, id: flashcard.id }
              : card
          )
        );
      } else {
        setAddedFlashcards((prevCards) =>
          prevCards.map((card) =>
            card.id === flashcard.id
              ? { ...improvedCard, id: flashcard.id }
              : card
          )
        );
      }

      // Notify the parent component about the edit
      onEditFlashcard({ ...improvedCard, id: flashcard.id });
    } catch (error) {
      console.error("Failed to improve flashcard:", error);
      // You might want to show an error message to the user here
    } finally {
      setImprovingCard(null);
    }
  };

  const FlashcardList = ({ flashcards, action, actionIcon, actionText }) => (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
      {flashcards
        .filter(
          (card) =>
            card.question.toLowerCase().includes(searchTerm.toLowerCase()) ||
            card.answer.toLowerCase().includes(searchTerm.toLowerCase())
        )
        .map((flashcard) => (
          <div
            key={flashcard.id}
            className="bg-zinc-700 rounded-lg overflow-hidden shadow-md hover:shadow-lg transition-shadow duration-300"
          >
            <div className="p-4 h-48 flex flex-col justify-between">
              <div className="flex-grow overflow-auto">
                <p className="text-zinc-100 text-sm font-medium mb-2">
                  Q:
                  <HtmlContent htmlContent={flashcard.question} />
                </p>
                <p className="text-zinc-300 text-sm">
                  A:
                  <HtmlContent htmlContent={flashcard.answer} />
                </p>
              </div>
              <div className="flex justify-end items-center mt-3 space-x-2">
                <Button
                  onClick={() => handleMakeBetter(flashcard)}
                  variant="ghost"
                  size="xs"
                  icon={Wand}
                  className="text-accent-sky hover:bg-accent-sky/10"
                  disabled={improvingCard === flashcard}
                >
                  {improvingCard === flashcard ? "Improving..." : "Make Better"}
                </Button>
                <Button
                  onClick={() => setEditingCard(flashcard)}
                  variant="ghost"
                  size="xs"
                  icon={Edit}
                  className="text-accent-sky hover:bg-accent-sky/10"
                >
                  Edit
                </Button>
                <Button
                  onClick={() => action(flashcard)}
                  variant="ghost"
                  size="xs"
                  icon={actionIcon}
                  className={`${
                    actionText === "Add"
                      ? "text-accent-emerald hover:bg-accent-emerald/10"
                      : "text-accent-rose hover:bg-accent-rose/10"
                  }`}
                >
                  {actionText}
                </Button>
              </div>
            </div>
          </div>
        ))}
    </div>
  );

  return (
    <div className="flashcard-display bg-zinc-800 border border-zinc-700 rounded-lg p-6 shadow-lg">
      <div className="flex mb-6 justify-between items-center">
        <div className="flex border-b border-zinc-700">
          <button
            className={`py-2 px-4 font-medium text-sm ${
              activeTab === "available"
                ? "text-accent-indigo border-b-2 border-accent-indigo"
                : "text-zinc-400 hover:text-zinc-100"
            }`}
            onClick={() => setActiveTab("available")}
          >
            Available Flashcards ({availableFlashcards.length})
          </button>
          <button
            className={`py-2 px-4 font-medium text-sm ${
              activeTab === "added"
                ? "text-accent-indigo border-b-2 border-accent-indigo"
                : "text-zinc-400 hover:text-zinc-100"
            }`}
            onClick={() => setActiveTab("added")}
          >
            Added Flashcards ({addedFlashcards.length})
          </button>
        </div>
        <Input
          type="text"
          placeholder="Search flashcards..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          icon={<Search className="w-5 h-5" />}
          className="w-64"
        />
      </div>
      <div className="overflow-auto max-h-[calc(100vh-300px)]">
        {activeTab === "available" ? (
          <FlashcardList
            flashcards={availableFlashcards}
            action={onAddFlashcard}
            actionIcon={Plus}
            actionText="Add"
          />
        ) : (
          <FlashcardList
            flashcards={addedFlashcards}
            action={onRemoveFlashcard}
            actionIcon={Minus}
            actionText="Remove"
          />
        )}
      </div>
      {editingCard && (
        <FlashcardEditor
          flashcard={editingCard}
          onSave={handleSaveEdit}
          onCancel={() => setEditingCard(null)}
        />
      )}
    </div>
  );
};

export default FlashcardDisplay;
