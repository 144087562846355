import React from "react";
import { Link, useLocation } from "react-router-dom";
import {
  Home,
  Dumbbell,
  Book,
  Compass,
  Settings,
  FileText,
} from "lucide-react";

const NavLinks = ({ session, isSidebar, isExpanded, isMobile }) => {
  const location = useLocation();

  const getLinkClass = (path) => {
    const baseClass =
      "transition-all duration-200 rounded-lg px-3 py-2 text-sm font-medium";
    const activeClass = "bg-accent-indigo/20 text-accent-indigo";
    const inactiveClass = "text-zinc-300 hover:bg-zinc-700 hover:text-zinc-100";
    return `${baseClass} ${
      location.pathname === path ? activeClass : inactiveClass
    }`;
  };

  if (session) {
    const links = [
      { to: "/dashboard", icon: Home, label: "Dashboard" },
      { to: "/practice", icon: Dumbbell, label: "Practice" },
      { to: "/decks", icon: Book, label: "Edit decks" },
      { to: "/documents", icon: FileText, label: "Documents" },
      { to: "/explore", icon: Compass, label: "Explore" },
      { to: "/settings", icon: Settings, label: "Settings" },
    ];

    return (
      <div
        className={`flex ${
          isMobile ? "justify-around w-full" : "flex-col space-y-1"
        }`}
      >
        {links.map((link) => (
          <Link
            key={link.to}
            to={link.to}
            className={`${getLinkClass(link.to)} ${
              !isExpanded || isMobile
                ? "flex justify-center"
                : "flex items-center"
            }`}
          >
            <link.icon
              className={`${isExpanded && !isMobile ? "mr-3" : ""} text-lg`}
              size={18}
            />
            {isExpanded && !isMobile && <span>{link.label}</span>}
          </Link>
        ))}
      </div>
    );
  } else {
    return (
      <>
        <a href="#get-started" className={getLinkClass("#get-started")}>
          Get started
        </a>
        <a href="#features" className={getLinkClass("#features")}>
          Features
        </a>
        <a href="#pricing" className={getLinkClass("#pricing")}>
          Pricing
        </a>
        <a href="#try-now" className={getLinkClass("#try-now")}>
          Try now
        </a>
      </>
    );
  }
};

export default NavLinks;
