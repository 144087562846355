import React from "react";
import { useNavigate } from "react-router-dom";

function Card({ title, description, items, route }) {
  const navigate = useNavigate();

  const handleClick = (id) => {
    navigate("/practice", { state: { id } });
  };

  return (
    <div className="bg-zinc-800 rounded-lg overflow-hidden shadow-lg transition-all duration-300 hover:shadow-xl border border-zinc-700">
      <div className="p-6">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-xl font-bold text-zinc-100">{title}</h3>
          {/* Uncomment if you want to add a "More" button
          <Link to={route}>
            <button className="text-sm text-zinc-400 hover:text-zinc-300 transition-colors">
              More
            </button>
          </Link>
          */}
        </div>
        <p className="text-zinc-400 mb-4">{description}</p>
        <ul className="space-y-2">
          {items.map((item, index) => (
            <button
              key={index}
              onClick={() => handleClick(item.id)}
              className="w-full text-left bg-zinc-700 hover:bg-[#685797] rounded-md p-3 transition-all duration-200 hover:shadow-md"
            >
              <span className="text-sm font-medium text-zinc-200">
                {item.name}
              </span>
            </button>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default Card;
