import React from "react";
import { X, Edit, Trash2 } from "lucide-react";
import { HtmlContent } from "../../services/htmlrender";

const CardDetailSlideOver = ({
  isCardDetailOpen,
  setIsCardDetailOpen,
  selectedCard,
  setIsEditing,
  deleteCard,
}) => {
  if (!isCardDetailOpen || !selectedCard) return null;

  return (
    <div className="fixed inset-0 overflow-hidden z-50">
      <div className="absolute inset-0 overflow-hidden">
        <div
          className="absolute inset-0 bg-zinc-900 bg-opacity-75 transition-opacity"
          onClick={() => setIsCardDetailOpen(false)}
        ></div>
        <section className="absolute inset-y-0 right-0 pl-10 max-w-full flex">
          <div className="w-screen max-w-md">
            <div className="h-full flex flex-col bg-zinc-800 shadow-xl overflow-y-scroll">
              <div className="flex items-center justify-between px-4 py-3 bg-zinc-700">
                <h2 className="text-lg font-medium text-zinc-100">
                  Card Details
                </h2>
                <button
                  className="p-1 rounded-md text-zinc-400 hover:text-zinc-200 focus:outline-none focus:ring-2 focus:ring-accent-indigo"
                  onClick={() => setIsCardDetailOpen(false)}
                >
                  <X className="h-5 w-5" aria-hidden="true" />
                </button>
              </div>
              <div className="flex-1 px-4 py-5 space-y-4">
                <div>
                  <h4 className="text-sm font-medium text-zinc-300 mb-2">
                    Question:
                  </h4>
                  <div className="bg-zinc-700 p-3 rounded-md text-zinc-100 text-sm">
                    <HtmlContent htmlContent={selectedCard.question} />
                  </div>
                </div>
                <div>
                  <h4 className="text-sm font-medium text-zinc-300 mb-2">
                    Answer:
                  </h4>
                  <div className="bg-zinc-700 p-3 rounded-md text-zinc-100 text-sm">
                    <HtmlContent htmlContent={selectedCard.answer} />
                  </div>
                </div>
                <div className="flex justify-end space-x-3 pt-4">
                  <button
                    className="inline-flex items-center px-3 py-1.5 text-xs font-medium rounded-md bg-accent-indigo/10 text-accent-indigo hover:bg-accent-indigo/20 transition-colors duration-200"
                    onClick={() => {
                      setIsEditing({ state: true, mode: "edit" });
                      setIsCardDetailOpen(false);
                    }}
                  >
                    <Edit className="w-3 h-3 mr-1.5" /> Edit
                  </button>
                  <button
                    className="inline-flex items-center px-3 py-1.5 text-xs font-medium rounded-md bg-accent-rose/10 text-accent-rose hover:bg-accent-rose/20 transition-colors duration-200"
                    onClick={() => {
                      if (selectedCard) {
                        deleteCard(selectedCard.id);
                        setIsCardDetailOpen(false);
                      }
                    }}
                  >
                    <Trash2 className="w-3 h-3 mr-1.5" /> Delete
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default CardDetailSlideOver;
