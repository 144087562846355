import React, { useState } from "react";
import { useDecks } from "../services/DeckProvider";
import CheckoutButton from "../components/CheckoutButton";

const plusMonthlyPriceId = "price_1PeBwGRvYbgZvjPSgZN6nXR4";
const proMonthlyPriceId = "price_1PeC2sRvYbgZvjPSthPwMQMA";
const plusAnnualPriceId = "price_1PeBwGRvYbgZvjPSqjPUtCd7";
const proAnnualPriceId = "price_1PeC2sRvYbgZvjPSPAGstSKQ";

const plans = [
  {
    name: "Free",
    monthlyPrice: 0,
    monthlyPriceId: "price_monthly_essential", // Replace with actual Stripe price ID
    annualPriceId: "price_annual_essential", // Replace with actual Stripe price ID
    features: [
      "Unlimited decks",
      "Unlimited cards per deck",
      "Standard AI model",
      "Generate 100 cards per 3 hours",
      "1 PDF per 24h",
      "PDFs with up to 20,000 words",
    ],
    isPopular: false,
  },
  {
    name: "Plus",
    monthlyPrice: 9.99,
    monthlyPriceId: plusMonthlyPriceId, // Replace with actual Stripe price ID
    annualPriceId: plusAnnualPriceId, // Replace with actual Stripe price ID
    features: [
      "Unlimited decks",
      "Up to 500 cards per deck",
      "500 cards per 3 hours",
      "5 PDFs per 24h",
      "Use the advanced model",
      "PDFs with up to 30,000 words",
      "Early access to new features",
    ],
    isPopular: true,
  },
  {
    name: "Pro",
    monthlyPrice: 15.99,
    monthlyPriceId: proMonthlyPriceId, // Replace with actual Stripe price ID
    annualPriceId: proAnnualPriceId, // Replace with actual Stripe price ID
    features: [
      "Unlimited decks",
      "Unlimited cards per deck",
      "Unlimited cards per 3 hours",
      "Unlimited PDFs per 24h",
      "Use the most intelligent model",
      "PDFs with up to 100,000 words",
      "Early access to new features",
    ],
    isPopular: false,
  },
];

function SubscriptionPlans({ session }) {
  const { membership } = useDecks();
  const [isAnnual, setIsAnnual] = useState(false);

  const userPlanIndex = plans.findIndex(
    (plan) => plan.name.toLowerCase() === membership?.toLowerCase()
  );

  const calculateAnnualPrice = (monthlyPrice) => {
    const annualPrice = monthlyPrice * 12;
    const discountedPrice = annualPrice * 0.7; // 30% off
    return discountedPrice.toFixed(2);
  };

  const calculateDiscount = (monthlyPrice) => {
    const annualPrice = monthlyPrice * 12;
    const discountedPrice = annualPrice * 0.7;
    const savings = annualPrice - discountedPrice;
    const discountPercentage = (savings / annualPrice) * 100;
    return Math.round(discountPercentage);
  };

  return (
    <section className="py-20 bg-gradient-to-b from-gray-900 to-black text-white">
      <div className="container mx-auto px-4">
        <h2 className="text-4xl md:text-5xl font-bold text-center mb-4">
          <span className="bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-500">
            Choose Your Plan
          </span>
        </h2>
        <p className="text-xl text-center mb-8 text-gray-300 max-w-3xl mx-auto">
          Select the perfect plan to enhance your learning experience
        </p>

        <div className="flex justify-center mb-12">
          <div className="bg-gray-800 rounded-full p-1">
            <button
              className={`px-4 py-2 rounded-full ${
                !isAnnual ? "bg-blue-500 text-white" : "text-gray-300"
              }`}
              onClick={() => setIsAnnual(false)}
            >
              Monthly
            </button>
            <button
              className={`px-4 py-2 rounded-full ${
                isAnnual ? "bg-blue-500 text-white" : "text-gray-300"
              }`}
              onClick={() => setIsAnnual(true)}
            >
              Annual
            </button>
          </div>
        </div>

        <div className="flex flex-wrap justify-center gap-8">
          {plans.map((plan, index) => {
            const isCurrentPlan = index === userPlanIndex;
            const price = isAnnual
              ? calculateAnnualPrice(plan.monthlyPrice)
              : plan.monthlyPrice.toFixed(2);
            const discount = calculateDiscount(plan.monthlyPrice);
            return (
              <div
                key={index}
                className={`bg-gray-800 rounded-2xl p-8 shadow-lg transition-all duration-300 hover:shadow-2xl hover:scale-105 w-full max-w-sm relative ${
                  plan.isPopular ? "border-2 border-purple-500" : ""
                } ${isCurrentPlan ? "border-2 border-green-500" : ""}`}
              >
                {plan.isPopular && !isCurrentPlan && (
                  <div className="absolute top-0 right-0 bg-purple-500 text-white text-sm font-bold py-1 px-3 rounded-bl-lg rounded-tr-lg">
                    MOST POPULAR
                  </div>
                )}
                {isCurrentPlan && (
                  <div className="absolute top-0 right-0 bg-green-500 text-white text-sm font-bold py-1 px-3 rounded-bl-lg rounded-tr-lg">
                    CURRENT PLAN
                  </div>
                )}
                <h3 className="text-2xl font-bold mb-4">{plan.name}</h3>
                <p className="text-4xl font-bold mb-2">
                  ${price}
                  <span className="text-lg font-normal text-gray-400">
                    /{isAnnual ? "year" : "month"}
                  </span>
                </p>
                {isAnnual && (
                  <p className="text-sm text-green-400 mb-6">
                    Save {discount}% with annual billing
                  </p>
                )}
                <ul className="mb-8 space-y-2">
                  {plan.features.map((feature, featureIndex) => (
                    <li key={featureIndex} className="flex items-center">
                      <svg
                        className="w-5 h-5 mr-2 text-green-400"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M5 13l4 4L19 7"
                        ></path>
                      </svg>
                      {feature}
                    </li>
                  ))}
                </ul>
                {isCurrentPlan ? (
                  <button
                    className="w-full font-bold py-3 px-4 rounded-lg bg-green-500 text-white cursor-default"
                    disabled
                  >
                    Current Plan
                  </button>
                ) : (
                  <button></button>
                  // <CheckoutButton
                  //   priceId={priceId}
                  //   user_id={user.sub}
                  //   className="w-full font-bold py-3 px-4 rounded-lg transition duration-300 bg-gradient-to-r from-blue-500 to-purple-600 text-white hover:from-blue-600 hover:to-purple-700"
                  // >
                  //   {index > userPlanIndex ? "Upgrade Plan" : "Downgrade Plan"}
                  // </CheckoutButton>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
}

export default SubscriptionPlans;
