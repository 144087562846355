import React, { useState, useEffect } from "react";
import { Paperclip, Wand2, Plus, Save, FileText } from "lucide-react";
import Button from "../Button";
import Tooltip from "../Tooltip";
import pdfToText from "react-pdftotext";
import SimpleModal from "../SimpleModal";

const InputSection = ({
  inputContent,
  onInputContentChange,
  onGenerate,
  onAddMore,
  onSave,
  isGenerateDisabled,
  isAddMoreDisabled,
  isSaveDisabled,
}) => {
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [extractedText, setExtractedText] = useState("");
  const [showTextModal, setShowTextModal] = useState(false);
  const [isLongText, setIsLongText] = useState(false);
  const [warningMessage, setWarningMessage] = useState("");

  const MAX_TEXT_LENGTH = 100000;

  useEffect(() => {
    setIsLongText(inputContent.length > 150);
    if (inputContent.length > MAX_TEXT_LENGTH) {
      setWarningMessage(
        `Warning: Text length exceeds ${MAX_TEXT_LENGTH} characters. Please shorten your text.`
      );
    } else {
      setWarningMessage("");
    }
  }, [inputContent]);

  const handleFileUpload = async (e) => {
    const files = Array.from(e.target.files);
    setUploadedFiles((prevFiles) => [...prevFiles, ...files]);

    for (const file of files) {
      if (file.type === "application/pdf") {
        try {
          const text = await pdfToText(file);
          setExtractedText((prevText) => prevText + "\n\n" + text);
          onInputContentChange((prevContent) => prevContent + "\n\n" + text);
        } catch (error) {
          console.error("Failed to extract text from PDF:", error);
          // You might want to show an error message to the user here
        }
      }
    }
  };

  const handleInputChange = (e) => {
    const content = e.target.value;
    onInputContentChange(content);
  };

  return (
    <div className="input-section bg-zinc-800 border border-zinc-700 rounded-lg p-4 shadow-lg">
      <div className="mb-4 flex items-center space-x-2">
        <div className="relative flex-grow">
          <textarea
            value={inputContent}
            onChange={handleInputChange}
            placeholder="Enter topic, comment, or paste text"
            className="w-full bg-zinc-700 text-zinc-100 rounded-lg px-4 py-2 pr-10 focus:outline-none focus:ring-2 focus:ring-accent-indigo/50 border border-zinc-600 resize-none"
            rows={isLongText ? 4 : 1}
          />
          <div className="absolute right-2 top-2 flex space-x-2">
            <Tooltip tooltipText="Attach PDF files">
              <label className="cursor-pointer">
                <Paperclip className="w-5 h-5 text-zinc-400 hover:text-accent-indigo transition-colors" />
                <input
                  type="file"
                  className="hidden"
                  accept=".pdf"
                  multiple
                  onChange={handleFileUpload}
                />
              </label>
            </Tooltip>
            {isLongText && (
              <Tooltip tooltipText="View full text">
                <button onClick={() => setShowTextModal(true)}>
                  <FileText className="w-5 h-5 text-zinc-400 hover:text-accent-indigo transition-colors" />
                </button>
              </Tooltip>
            )}
          </div>
        </div>
      </div>
      {warningMessage && (
        <div className="mb-4 text-accent-yellow text-sm">{warningMessage}</div>
      )}
      {uploadedFiles.length > 0 && (
        <div className="mb-4">
          <h3 className="text-xs font-medium text-zinc-400 mb-1">
            Attached files:
          </h3>
          <ul className="text-xs text-zinc-500">
            {uploadedFiles.map((file, index) => (
              <li key={index}>{file.name}</li>
            ))}
          </ul>
        </div>
      )}
      <div className="flex justify-between items-center space-x-2">
        <Button
          onClick={onGenerate}
          disabled={isGenerateDisabled || inputContent.length > MAX_TEXT_LENGTH}
          icon={Wand2}
          size="sm"
          className="bg-accent-indigo/10 text-accent-indigo hover:bg-accent-indigo/20 disabled:bg-zinc-700 disabled:text-zinc-500"
        >
          Generate
        </Button>
        <Button
          onClick={onAddMore}
          disabled={isAddMoreDisabled}
          icon={Plus}
          size="sm"
          className="bg-accent-emerald/10 text-accent-emerald hover:bg-accent-emerald/20 disabled:bg-zinc-700 disabled:text-zinc-500"
        >
          Add More
        </Button>
        <Button
          onClick={onSave}
          disabled={isSaveDisabled}
          icon={Save}
          size="sm"
          className="bg-accent-sky/10 text-accent-sky hover:bg-accent-sky/20 disabled:bg-zinc-700 disabled:text-zinc-500"
        >
          Save
        </Button>
      </div>
      <div className="mt-2 text-xs text-zinc-500">
        Character count: {inputContent.length} / {MAX_TEXT_LENGTH}
      </div>
      {showTextModal && (
        <SimpleModal setIsOpen={setShowTextModal} isOpen={showTextModal}>
          <textarea
            className="w-full h-96 bg-zinc-800 text-white p-2 rounded-lg"
            value={inputContent}
            onChange={handleInputChange}
          ></textarea>
        </SimpleModal>
      )}
    </div>
  );
};

export default InputSection;
