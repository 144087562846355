import React from "react";
import { LoadingIndicator } from "../../components/LoadingAnimations";

const LoadingScreen = () => (
  <div className="flex items-center justify-center h-screen">
    <LoadingIndicator />
  </div>
);

export default LoadingScreen;
