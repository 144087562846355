import React from "react";
import { supabase } from "../../supabaseClient";
import UserSync from "../../components/UserSync";
import SettingsForm from "./SettingsForm";
import { FiLogOut } from "react-icons/fi";

const Settings = ({ session }) => {
  const handleLogout = async () => {
    const { error } = await supabase.auth.signOut();
    if (error) console.log("Error logging out:", error.message);
  };

  return (
    <UserSync session={session}>
      {(userData) => (
        <div className="min-h-screen bg-gradient-to-b from-zinc-900 to-zinc-800 text-white p-4 sm:p-6 md:p-8">
          <div className="max-w-3xl mx-auto bg-zinc-800 rounded-lg shadow-lg p-6 sm:p-8">
            <h1 className="text-3xl font-bold mb-8 text-center">Settings</h1>
            <SettingsForm initialData={userData} session={session} />
          </div>
          <div className="mt-4">
            <button
              onClick={handleLogout}
              className="flex items-center justify-center w-full bg-zinc-600 hover:bg-zinc-700 text-white font-bold py-3 px-4 rounded-md transition duration-300 shadow-md"
            >
              <FiLogOut className="mr-2" />
              Logout
            </button>
          </div>
        </div>
      )}
    </UserSync>
  );
};

export default Settings;
