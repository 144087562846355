import React from "react";
import { Save, Trash2, Heart, Sparkles } from "lucide-react";
import Button from "../Button";
import Tooltip from "../Tooltip";
import AddOptionsDropdown from "../AddOptionsDropdown";
import LoadingSpinner from "../GenerateCards/LoadingSpinner";

const Toolbar = ({
  selectedDeck,
  onSave,
  onFavorite,
  onDeleteDeck,
  setShowCreateDeckModal,
  onGeneratePage,
  setIsEditing,
  decks,
  isGenerating,
}) => (
  <div className="toolbar bg-zinc-800 shadow-md rounded-lg p-2 border border-zinc-700 m-2">
    <div className="flex flex-wrap items-center justify-between gap-4">
      <div className="flex items-center space-x-3">
        <Tooltip tooltipText="Create new content">
          <AddOptionsDropdown
            selectedDeck={selectedDeck}
            setShowCreateCardModal={setIsEditing}
            setShowCreateDeckModal={setShowCreateDeckModal}
            decks={decks}
            className="bg-accent-indigo text-white hover:bg-accent-indigo/90 px-4 py-2 rounded-md transition-colors duration-200"
          />
        </Tooltip>
        {selectedDeck && (
          <>
            <Tooltip
              tooltipText={
                selectedDeck.favorite
                  ? "Remove from favorites"
                  : "Add to favorites"
              }
            >
              <Button
                className="toolbar-btn bg-accent-yellow text-zinc-900 hover:bg-accent-yellow/90"
                onClick={() => onFavorite(selectedDeck)}
              >
                <Heart
                  className="w-5 h-5"
                  fill={selectedDeck.favorite ? "currentColor" : "none"}
                />
              </Button>
            </Tooltip>
            <Tooltip tooltipText="Delete entire deck">
              <Button
                className="toolbar-btn bg-accent-rose text-white hover:bg-accent-rose/90"
                onClick={onDeleteDeck}
              >
                <Trash2 className="w-5 h-5" />
              </Button>
            </Tooltip>
            <Tooltip tooltipText="Generate a Page">
              <Button
                className="toolbar-btn bg-black text-white hover:bg-accent-emerald/90"
                onClick={onGeneratePage}
                isLoading={isGenerating}
                disabled={selectedDeck.cards.length === 0}
              >
                {isGenerating ? (
                  <LoadingSpinner />
                ) : (
                  <Sparkles className="w-5 h-5" />
                )}
              </Button>
            </Tooltip>
            {/* <Tooltip tooltipText="Save changes">
              <Button
                className="toolbar-btn bg-accent-emerald text-white hover:bg-accent-emerald/90"
                onClick={onSave}
              >
                <Save className="w-5 h-5" />
              </Button>
            </Tooltip> */}
          </>
        )}
      </div>
    </div>
  </div>
);

export default Toolbar;
