import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { useDecks } from "../services/DeckProvider";
import { notifyError, notifySuccess } from "./Notification.js";
import SimpleModal from "./SimpleModal.js";
import CreateCardModal from "./CreateCardModal.js";
import Button from "./Button.js";
import { ArrowRight } from "lucide-react";

const Toolbar = ({
  openCreateNewDeck,
  setOpenCreateNewDeck,
  setCreateByTopicModal,
  setUploadTextModal,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [showCreateCard, setShowCreateCard] = useState(false);
  const dropdownRef = useRef(null);
  const navigate = useNavigate();
  const { setSelectedDeck, user } = useDecks();

  async function createDeck(deck) {
    const api_host = process.env.REACT_APP_API_HOST;
    try {
      const response = await fetch(`${api_host}/create-deck`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
        body: JSON.stringify(deck),
      });
      const data = await response.json();
      notifySuccess("Deck was successfully created!");
      setSelectedDeck?.(data);
      return data;
    } catch (error) {
      notifyError("There was an error creating the deck");
    }
  }

  const handleRedirect = () => {
    navigate("/decks");
  };

  const options = [
    { name: "Create new deck", function: () => setOpenCreateNewDeck(true) },
    {
      name: "Upload PDF or text",
      function: async () => {
        const deckId = uuidv4();
        await createDeck({
          id: deckId,
          name: "Untitled Deck",
          tags: [],
          cards: [],
          user_id: user.sub,
          origin_owner_id: user.sub,
          created_at: new Date().toISOString(),
        });
        navigate(`/decks/pdf/${deckId}`);
      },
    },
    {
      name: "Generate cards by topic",
      function: () => {
        setCreateByTopicModal(true);
      },
      //   const deckId = uuidv4();
      //   await createDeck({
      //     id: deckId,
      //     name: "Untitled Deck",
      //     tags: [],
      //     cards: [],
      //     user_id: user.sub,
      //     origin_owner_id: user.sub,
      //     created_at: new Date().toISOString(),
      //   });
      //   navigate(`/decks/${deckId}`);
      // },
    },
  ];

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="relative inline-block text-left" ref={dropdownRef}>
      <Button onClick={handleRedirect} variant="secondary" icon={ArrowRight}>
        Go to Decks
      </Button>
      {/* <button
        type="button"
        className="inline-flex items-center justify-center w-full rounded-md shadow-sm px-3 py-2 sm:px-4 sm:py-2 bg-indigo-500 text-xs sm:text-sm font-semibold text-white hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500 transition duration-150 ease-in-out"
        onClick={() => setIsOpen(!isOpen)}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-4 h-4 sm:w-5 sm:h-5 mr-1 sm:mr-2"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
          />
        </svg>
        <span className="hidden sm:inline">Create content</span>
        <span className="sm:hidden">Create</span>
        <svg
          className="ml-1 sm:ml-2 h-4 w-4 sm:h-5 sm:w-5"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true"
        >
          <path
            fillRule="evenodd"
            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
            clipRule="evenodd"
          />
        </svg>
      </button>
      <div
        className={`${
          isOpen
            ? "transform opacity-100 scale-100"
            : "transform opacity-0 scale-95 pointer-events-none"
        } transition ease-out duration-100 absolute right-0 mt-2 w-48 sm:w-56 rounded-md shadow-lg bg-background ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none`}
        role="menu"
        aria-orientation="vertical"
        aria-labelledby="options-menu"
      >
        <div className="py-1" role="none">
          {options.map((option) => (
            <button
              key={option.name}
              onClick={() => {
                option.function();
                setIsOpen(false);
              }}
              className="group flex items-center px-4 py-2 text-sm text-white hover:bg-zinc-700 w-full text-left"
              role="menuitem"
            >
              {option.name}
            </button>
          ))}
        </div>
      </div> */}

      {showCreateCard && (
        <SimpleModal isOpen={showCreateCard} setIsOpen={setShowCreateCard}>
          <CreateCardModal />
        </SimpleModal>
      )}
    </div>
  );
};

export default Toolbar;
