import React, { useState, useEffect } from "react";

function ReportIssue({ onClose }) {
  const [isVisible, setIsVisible] = useState(false);
  const contactEmail = "kontakt@quantiqa.de"; // Replace with your contact email

  useEffect(() => {
    setIsVisible(true);
  }, []);

  return (
    <div className="p-6 bg-zinc-800 rounded-lg shadow-xl">
      <h2 className="text-2xl font-bold mb-4 text-white">Report an Issue</h2>
      <div
        className={`transition-opacity duration-500 ${
          isVisible ? "opacity-100" : "opacity-0"
        }`}
      >
        <p className="mb-4 text-gray-300">
          We're sorry you're experiencing an issue. Please send an email to the
          following address to report your problem:
        </p>
        <a
          href={`mailto:${contactEmail}`}
          className="text-xl font-bold text-indigo-400 hover:text-indigo-300 transition-colors duration-150"
        >
          {contactEmail}
        </a>
        <p className="mt-4 text-gray-300">
          We appreciate your patience and will address your issue as soon as
          possible.
        </p>
        <button
          onClick={onClose}
          className="mt-6 w-full bg-indigo-600 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transition duration-150 ease-in-out"
        >
          Close
        </button>
      </div>
    </div>
  );
}

export default ReportIssue;

// function ReportIssue({ user, onClose }) {
//   const [formData, setFormData] = useState({
//     user_name: user?.name || "",
//     email: user?.email || "",
//     user_id: user?.id || "",
//     message: "",
//   });
//   const [isSubmitting, setIsSubmitting] = useState(false);
//   const [submitMessage, setSubmitMessage] = useState("");
//   const [isVisible, setIsVisible] = useState(false);
//   const api_host = process.env.REACT_APP_API_HOST;

//   useEffect(() => {
//     setIsVisible(true);
//   }, []);

//   const handleChange = (e) => {
//     setFormData({ ...formData, [e.target.name]: e.target.value });
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     setIsSubmitting(true);
//     setSubmitMessage("");
//     try {
//       const response = await fetch(`${api_host}/api/user-report`, {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify(formData),
//       });
//       if (response.ok) {
//         setSubmitMessage("Report sent successfully!");
//         setFormData({ ...formData, message: "" });
//         setTimeout(() => {
//           onClose();
//         }, 2000);
//       } else {
//         const errorData = await response.json();
//         setSubmitMessage(
//           errorData.detail || "Error sending report. Please try again later."
//         );
//       }
//     } catch (error) {
//       console.error("Error:", error);
//       setSubmitMessage("An error occurred. Please try again later.");
//     } finally {
//       setIsSubmitting(false);
//     }
//   };

//   return (
//     <div className="p-6 bg-zinc-800 rounded-lg shadow-xl">
//       <h2 className="text-2xl font-bold mb-4 text-white">Report an Issue</h2>
//       <p className="mb-4 text-gray-300">
//         We're sorry you're experiencing an issue. Please describe the problem
//         below.
//       </p>
//       <form
//         onSubmit={handleSubmit}
//         className={`transition-opacity duration-500 ${
//           isVisible ? "opacity-100" : "opacity-0"
//         }`}
//       >
//         <div className="mb-4">
//           <label
//             htmlFor="message"
//             className="block text-sm font-medium text-gray-300"
//           >
//             Message
//           </label>
//           <textarea
//             id="message"
//             name="message"
//             value={formData.message}
//             onChange={handleChange}
//             required
//             rows="4"
//             className="mt-1 block w-full rounded-md bg-zinc-700 text-white border-gray-600 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
//           ></textarea>
//         </div>
//         <button
//           type="submit"
//           disabled={isSubmitting}
//           className="w-full bg-indigo-600 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transition duration-150 ease-in-out"
//         >
//           {isSubmitting ? "Sending..." : "Submit Report"}
//         </button>
//       </form>
//       {submitMessage && (
//         <p className="mt-4 text-center text-green-500">{submitMessage}</p>
//       )}
//     </div>
//   );
// }

// export default ReportIssue;
