// Function to calculate the average rating of a card
function calculateAverageRating(ratings) {
  const sum = ratings.reduce((acc, curr) => acc + curr, 0);
  return sum / ratings.length;
}

//TODO: This function have to be tested first. It is probably better than the current algorithm
export const improvedRateAndAdvance = (
  rating,
  selectedDeck,
  setSelectedDeck,
  currentFlashcardIndex,
  setCurrentFlashcardIndex,
  setFlip
) => {
  const updatedDeck = { ...selectedDeck, cards: [...selectedDeck.cards] };

  // Initialize SRS data for all cards if missing
  updatedDeck.cards = updatedDeck.cards.map((card) => ({
    ...card,
    srsData: card.srsData || {
      interval: 1,
      easeFactor: 2.5,
      consecutiveSuccesses: 0,
    },
    nextReviewDate:
      card.nextReviewDate || new Date(Date.now() + 86400000).toISOString(),
  }));

  const currentFlashcard = updatedDeck.cards[currentFlashcardIndex];

  // Update SRS data based on rating
  if (rating >= 3) {
    currentFlashcard.srsData.consecutiveSuccesses++;
    currentFlashcard.srsData.interval *= currentFlashcard.srsData.easeFactor;
  } else {
    currentFlashcard.srsData.consecutiveSuccesses = 0;
    currentFlashcard.srsData.interval = 1;
  }

  currentFlashcard.srsData.easeFactor = Math.max(
    1.3,
    currentFlashcard.srsData.easeFactor + 0.1 - (5 - rating) * 0.08
  );

  currentFlashcard.nextReviewDate = new Date(
    Date.now() + currentFlashcard.srsData.interval * 86400000
  ).toISOString();

  // Sort cards by nextReviewDate
  updatedDeck.cards.sort(
    (a, b) => new Date(a.nextReviewDate) - new Date(b.nextReviewDate)
  );

  setSelectedDeck(updatedDeck);

  // Find the index of the next due card that isn't the current card
  const nextIndex = updatedDeck.cards.findIndex(
    (card, index) =>
      new Date(card.nextReviewDate) > new Date() &&
      index !== currentFlashcardIndex
  );

  // If no next card is found that isn't the current card, find any next card
  const newIndex =
    nextIndex !== -1
      ? nextIndex
      : updatedDeck.cards.findIndex(
          (card, index) => new Date(card.nextReviewDate) > new Date()
        );

  // Set the index to 0 if no suitable cards are found, otherwise to the found index
  setCurrentFlashcardIndex(newIndex !== -1 ? newIndex : 0);

  setFlip(false);
  return updatedDeck;
};
