import { loadStripe } from "@stripe/stripe-js";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

function CheckoutButton({ priceId, className, children, user_id }) {
  const handleClick = async (event) => {
    const stripe = await stripePromise;
    const response = await fetch(
      "http://localhost:8000/api/create-checkout-session",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ price_id: priceId, user_id: user_id }),
      }
    );
    const session = await response.json();
    const result = await stripe.redirectToCheckout({
      sessionId: session.sessionId,
    });
    if (result.error) {
      console.error(result.error);
    }
  };

  return (
    <button onClick={handleClick} className={className}>
      {children}
    </button>
  );
}

export default CheckoutButton;
