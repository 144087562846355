import React from "react";

const Nutzungsbedingungen = () => {
  return (
    <div className="bg-gradient-to-r from-zinc-800 via-gray-900 to-black text-white py-16 sm:py-32">
      <div className="container mx-auto px-6 lg:px-8">
        <h1 className="text-4xl font-bold mb-6 text-center">
          Nutzungsbedingungen
        </h1>
        <div className="bg-gray-800 p-8 rounded-lg shadow-md">
          <h2 className="text-2xl font-semibold mb-4">1. Allgemeines</h2>
          <p className="mb-4">
            Durch den Zugriff auf und die Nutzung dieser Website erklären Sie
            sich mit den folgenden Nutzungsbedingungen einverstanden. Wenn Sie
            nicht an diese Bedingungen gebunden sein möchten, dürfen Sie diese
            Website nicht nutzen.
          </p>

          <h2 className="text-2xl font-semibold mt-6 mb-4">
            2. Nutzung der Website
          </h2>
          <p className="mb-4">
            Die Inhalte dieser Website dürfen nur zu Informationszwecken und für
            den persönlichen, nicht kommerziellen Gebrauch verwendet werden. Es
            ist nicht gestattet, Inhalte der Website ohne ausdrückliche
            schriftliche Genehmigung des Betreibers zu verändern, zu kopieren,
            zu verbreiten, zu übertragen, darzustellen, aufzuführen, zu
            reproduzieren, zu veröffentlichen, zu lizenzieren, abgeleitete Werke
            zu erstellen, zu übertragen oder zu verkaufen.
          </p>

          <h2 className="text-2xl font-semibold mt-6 mb-4">
            3. Haftungsausschluss
          </h2>
          <p className="mb-4">
            Die Inhalte dieser Website werden "wie besehen" bereitgestellt, ohne
            jegliche ausdrückliche oder stillschweigende Gewährleistung. Der
            Betreiber übernimmt keine Gewährleistung oder Haftung für die
            Genauigkeit, Vollständigkeit oder Aktualität der Inhalte. Die
            Nutzung der Inhalte der Website erfolgt auf eigene Gefahr des
            Nutzers.
          </p>

          <h2 className="text-2xl font-semibold mt-6 mb-4">
            4. Änderungen der Nutzungsbedingungen
          </h2>
          <p className="mb-4">
            Der Betreiber behält sich das Recht vor, diese Nutzungsbedingungen
            jederzeit ohne vorherige Ankündigung zu ändern. Durch die weitere
            Nutzung der Website erklären Sie sich mit den jeweils aktuellen
            Nutzungsbedingungen einverstanden.
          </p>

          <h2 className="text-2xl font-semibold mt-6 mb-4">
            5. Anwendbares Recht
          </h2>
          <p className="mb-4">
            Diese Nutzungsbedingungen unterliegen dem Recht der Bundesrepublik
            Deutschland. Gerichtsstand für alle Streitigkeiten aus oder im
            Zusammenhang mit diesen Nutzungsbedingungen ist der Sitz des
            Betreibers.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Nutzungsbedingungen;
