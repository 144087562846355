import React from "react";
import { FiTrash2 } from "react-icons/fi";

const DeleteAccountButton = ({ onDelete }) => (
  <button
    onClick={onDelete}
    className="flex items-center justify-center w-full bg-red-600 hover:bg-red-700 text-white font-bold py-3 px-4 rounded-md transition duration-300 shadow-md"
  >
    <FiTrash2 className="mr-2" />
    Delete Account
  </button>
);

export default DeleteAccountButton;
