import React from "react";
import { Loader2 } from "lucide-react";

const Button = ({
  onClick,
  children,
  icon: Icon,
  disabled = false,
  variant = "primary",
  size = "md",
  isLoading = false,
  fullWidth = false,
  className = "",
  ...props
}) => {
  const baseClasses =
    "inline-flex items-center justify-center rounded-md font-medium transition-all duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-offset-2";

  const variants = {
    primary:
      "bg-indigo-600 text-white hover:bg-indigo-700 focus:ring-indigo-500",
    secondary:
      "bg-zinc-700 text-zinc-100 hover:bg-zinc-600 focus:ring-zinc-500",
    outline:
      "bg-transparent border border-zinc-600 text-zinc-100 hover:bg-zinc-800 focus:ring-zinc-500",
    ghost: "bg-transparent text-zinc-100 hover:bg-zinc-800 focus:ring-zinc-500",
  };

  const sizes = {
    xs: "px-2 py-1 text-xs",
    sm: "px-2.5 py-1.5 text-sm",
    md: "px-3 py-2 text-sm",
    lg: "px-4 py-2 text-base",
  };

  const iconSizes = {
    xs: 12,
    sm: 14,
    md: 16,
    lg: 18,
  };

  const combinedClasses = `
    ${baseClasses}
    ${variants[variant]}
    ${sizes[size]}
    ${fullWidth ? "w-full" : ""}
    ${disabled ? "opacity-50 cursor-not-allowed" : "hover:scale-102"}
    ${className}
  `;

  return (
    <button
      className={combinedClasses}
      onClick={onClick}
      disabled={disabled || isLoading}
      {...props}
    >
      {isLoading ? (
        <Loader2 className="mr-1.5 animate-spin" size={iconSizes[size]} />
      ) : Icon ? (
        <Icon className="mr-1.5" size={iconSizes[size]} />
      ) : null}
      {children}
    </button>
  );
};

export default Button;
