import React, { useState } from "react";
import { Sun, Moon } from "lucide-react";

const SimpleModal = ({
  isOpen,
  setIsOpen,
  children,
  closeButton = true,
  className = "",
  actionButtons = [], // Add actionButtons prop
}) => {
  const [mode, setMode] = useState("dark");

  if (!isOpen) return null;

  const toggleMode = () => {
    setMode(mode === "dark" ? "light" : "dark");
  };

  const darkModeClasses = {
    backdrop: "bg-black bg-opacity-50",
    modal: "bg-zinc-800 text-white",
    closeButton: "bg-zinc-700 hover:bg-zinc-600 text-white",
  };

  const lightModeClasses = {
    backdrop: "bg-gray-200 bg-opacity-50",
    modal: "bg-white text-black",
    closeButton: "bg-gray-200 hover:bg-gray-300 text-black",
  };

  const classes = mode === "dark" ? darkModeClasses : lightModeClasses;

  return (
    <div
      className={`fixed inset-0 flex items-center justify-center ${classes.backdrop} z-50 p-4 ${className}`}
      onClick={() => setIsOpen(false)}
    >
      <div
        className={`${classes.modal} rounded-lg shadow-lg p-4 sm:p-6 w-full max-w-5xl max-h-[90vh] overflow-y-auto`}
        onClick={(e) => e.stopPropagation()}
      >
        <div className="flex justify-between items-center mb-4">
          <div className="flex items-center space-x-2">
            <button
              onClick={toggleMode}
              className={`p-2 rounded-full ${
                mode === "dark" ? "bg-zinc-700" : "bg-gray-200"
              }`}
            >
              {mode === "dark" ? (
                <Sun className="w-5 h-5 text-yellow-400" />
              ) : (
                <Moon className="w-5 h-5 text-zinc-600" />
              )}
            </button>
            {actionButtons.map((button, index) => (
              <div key={index}>{button}</div>
            ))}
          </div>
          {closeButton && (
            <button
              className={`${classes.closeButton} font-bold py-2 px-4 rounded text-sm sm:text-base`}
              onClick={() => setIsOpen(false)}
            >
              Close
            </button>
          )}
        </div>
        <div className="overflow-y-auto max-h-[calc(90vh-8rem)]">
          <div
            className={`prose ${
              mode === "dark" ? "prose-invert" : ""
            } max-w-none`}
          >
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SimpleModal;
