import { useState } from "react";
import { useDecks } from "./DeckProvider";

const cardsToJson = (cards) => {
  const json_formatted_cards = JSON.stringify(cards);
  return json_formatted_cards;
};
export const more_cards = (cards, numberOfNewCards = 5) => {
  let formatted_cards = cardsToJson(cards);
  return formatted_cards;
  // Your fetch logic here
};

const api_host = process.env.REACT_APP_API_HOST;

export const useSaveDeck = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { refreshDecks } = useDecks();

  const saveDeck = async (
    selectedDeck,
    setSelectedDeck,
    setShowError,
    setShowSuccess
  ) => {
    setLoading(true);
    setError(null);

    try {
      const url = `${api_host}/deck/${selectedDeck.id}`;

      const header = {
        method: "PUT",
        body: JSON.stringify(selectedDeck),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
        redirect: "follow",
      };

      const response = await fetch(url, header);
      if (!response.ok) {
        setShowError(true);
        throw new Error("Failed to update deck");
      } else {
        setShowSuccess(true);
      }

      const data = await response.json();
      const returned_deck = data.response.data[0];

      // Use refreshDecks to update the deck in the context
      await refreshDecks(returned_deck);

      setSelectedDeck(returned_deck);
    } catch (error) {
      console.error("Error during fetch operation: ", error.message);
      setError(error.message);
      setShowError(true);
    } finally {
      setLoading(false);
    }
  };

  return { saveDeck, loading, error };
};

export const deleteDeck = async (deckId) => {
  const url = `${api_host}/api/decks/${deckId}`;
  try {
    const response = await fetch(url, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });

    if (!response.ok) {
      throw new Error("Failed to delete the deck");
    }

    // Remove the deck from localStorage
    let lastUsedDecks = JSON.parse(localStorage.getItem("lastUsedDecks")) || [];
    lastUsedDecks = lastUsedDecks.filter((deck) => deck.id !== deckId);
    localStorage.setItem("lastUsedDecks", JSON.stringify(lastUsedDecks));
  } catch (error) {
    console.error("Error during delete operation:", error.message);
    return false;
  }
};

export const fetchDecks = async (user_id, access_token) => {
  const url = `${api_host}/get-decks/${user_id}`;
  const header = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${access_token}`,
    },
    redirect: "follow",
  };
  try {
    const response = await fetch(url, header);
    const data = await response.json();
    return data.data;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const fetchMembership = async (user_id, access_token) => {
  const url = `${api_host}/api/get-membership/${user_id}`;
  const header = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${access_token}`,
    },
    redirect: "follow",
  };
  try {
    const response = await fetch(url, header);
    const data = await response.json();
    const membership = data.data[0].membership;
    return membership;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const getUser = async (user_id) => {
  try {
    const response = await fetch(`${api_host}/api/users/${user_id}`);
    if (!response.ok) {
      throw new Error("Failed to fetch user settings");
    }
    const data = await response.json();
    return data["data"];
  } catch (error) {
    console.error("Error fetching user settings:", error);
  }
};

// apicalls.js

const API_BASE_URL = "/api"; // Adjust this if your API has a different base URL
export const sendChatMessage = async (prompt, conversationId, messages) => {
  try {
    const token = localStorage.getItem("token"); // Assuming you store the token in localStorage
    const response = await fetch(`${api_host}/api/chat`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
      body: JSON.stringify({
        conversation_id: conversationId,
        prompt,
        messages,
      }),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error sending chat message:", error);
    throw error;
  }
};

// Fetch the user settings
export const fetchUserSettings = async (userId) => {
  try {
    const response = await fetch(`${api_host}/api/users/${userId}`);
    if (!response.ok) {
      throw new Error("Failed to fetch user settings");
    }
    const data = await response.json();
    return {
      collectData: data.data[0].collect_data,
      username: data.data[0].name || "",
      currentPlan: "Premium", // This should be fetched from your backend
    };
  } catch (error) {
    console.error("Error fetching user settings:", error);
    return null;
  }
};

export const updateUserData = async (userId, data) => {
  try {
    const response = await fetch(`${api_host}/api/users/${userId}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      throw new Error("Failed to update user data");
    }

    return await response.json();
  } catch (error) {
    console.error("Error updating user data:", error);
    throw error;
  }
};
