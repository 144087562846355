import React from "react";
import { useNavigate } from "react-router-dom";

import { PlusCircle } from "lucide-react";

export const NoItems = () => {
  const navigate = useNavigate();

  const handleRedirect = () => {
    navigate("/decks");
  };

  return (
    <div className="flex   bg-zinc-900 p-4">
      <div className="bg-zinc-800 rounded-lg shadow-xl text-center max-w-md w-full p-8 border border-zinc-700">
        <div className="bg-zinc-700 rounded-full w-20 h-20 flex items-center justify-center mx-auto mb-6">
          <PlusCircle className="w-10 h-10 text-accent-indigo" />
        </div>
        <h2 className="text-2xl font-semibold text-zinc-100 mb-4">
          No cards available
        </h2>
        <p className="text-zinc-300 mb-8">
          It looks like you don't have any cards at the moment. Let's create
          some!
        </p>
        <button
          onClick={handleRedirect}
          className="w-full px-6 py-3 bg-accent-indigo text-zinc-100 font-semibold rounded-lg shadow-md hover:bg-accent-indigo/80 focus:outline-none focus:ring-2 focus:ring-accent-indigo focus:ring-opacity-75 transition-all duration-200"
        >
          <PlusCircle className="w-5 h-5 inline-block mr-2" />
          Create New Cards
        </button>
      </div>
    </div>
  );
};
export const NoDecksAvailable = ({ setShowCreateDeckModal, decks }) => {
  if (decks?.length === 0) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen bg-inherit">
        <div className="bg-white p-8 rounded-lg shadow-lg text-center">
          <h2 className="text-2xl font-semibold text-gray-800 mb-4">
            No decks available.
          </h2>
          <p className="text-gray-600 mb-6">
            It looks like you have no decks available at the moment.
          </p>
          <button
            onClick={() => setShowCreateDeckModal(true)}
            className="px-6 py-3 bg-blue-500 text-white font-semibold rounded-lg shadow-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75"
          >
            Create new deck
          </button>
        </div>
      </div>
    );
  }
};
