import React from "react";
import { Search, ChevronLeft, ChevronRight } from "lucide-react";

const DecksSidebar = ({
  isDecksSidebarCollapsed,
  toggleDecksSidebar,
  searchTerm,
  setSearchTerm,
  filteredDecks,
  selectedDeck,
  setSelectedDeck,
}) => {
  return (
    <div
      className={`fixed md:static border border-zinc-700 inset-y-0 left-0 rounded-lg transform ${
        isDecksSidebarCollapsed
          ? "-translate-x-full md:translate-x-0 md:w-12 bg-zinc-900"
          : "translate-x-0 w-64 md:w-1/5 lg:w-1/6 bg-zinc-900"
      } transition-all duration-300 ease-in-out ${
        !isDecksSidebarCollapsed ? "z-30" : "z-20"
      } rounded-lg flex flex-col h-full`}
      style={{ maxHeight: "calc(100vh - 4rem)" }}
    >
      <div
        className={`p-4 flex justify-between items-center flex-shrink-0 border-b border-zinc-800`}
      >
        <h2
          className={`text-lg font-bold text-zinc-100 ${
            isDecksSidebarCollapsed ? "hidden" : ""
          }`}
        >
          Decks
        </h2>
        <button
          onClick={toggleDecksSidebar}
          className="p-2 hover:bg-zinc-800 bg-zinc-900 rounded-lg transition-colors duration-200 focus:outline-none focus:ring-1 focus:ring-zinc-700"
        >
          {isDecksSidebarCollapsed ? (
            <ChevronRight className="w-5 h-5 text-zinc-400" />
          ) : (
            <ChevronLeft className="w-5 h-5 text-zinc-400" />
          )}
        </button>
      </div>
      {!isDecksSidebarCollapsed && (
        <div className="overflow-y-auto flex-1 rounded-b-lg">
          <div className="p-4 flex-shrink-0">
            <div className="relative">
              <input
                type="text"
                placeholder="Search decks..."
                className="w-full pl-10 pr-4 py-2 text-sm rounded-lg bg-zinc-800 text-zinc-100 placeholder-zinc-500 focus:outline-none focus:ring-2 focus:ring-accent-indigo border border-zinc-700 transition-colors duration-200"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <Search className="absolute left-3 top-2.5 text-zinc-500 w-4 h-4" />
            </div>
          </div>
          <div className="flex-1 overflow-y-auto px-2">
            {filteredDecks.map((deck) => (
              <div
                key={deck.id}
                className={`p-3 my-1 rounded-lg cursor-pointer transition-colors duration-200 ${
                  selectedDeck?.id === deck.id
                    ? "bg-accent-indigo text-zinc-100"
                    : "hover:bg-zinc-800 text-zinc-300"
                }`}
                onClick={() => setSelectedDeck(deck)}
              >
                <div className="flex justify-between items-center">
                  <span className="font-medium text-sm">{deck.name}</span>
                  <span
                    className={`text-xs px-2 py-1 rounded-full ${
                      selectedDeck?.id === deck.id
                        ? "bg-accent-indigo/50 text-zinc-100"
                        : "bg-zinc-700 text-zinc-300"
                    }`}
                  >
                    {deck.cards.length}
                  </span>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default DecksSidebar;
