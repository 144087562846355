import React from "react";
import { Helmet } from "react-helmet";

const AthenaSEO = ({
  title = "AthenaAI - Revolutionize Your Learning",
  description = "Unlock the power of personalized learning with AthenaAI. Adaptive study plans, efficient progress, and engaging content tailored to your needs.",
  keywords = "AI learning, personalized education, adaptive study, flashcards, quizzes",
  image = "https://athenaai.me/og-image.jpg", // Replace with actual image URL
  url = "https://athenaai.me",
  type = "website",
}) => {
  return (
    <Helmet>
      {/* Standard metadata */}
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
      {/* Open Graph / Facebook */}
      <meta property="og:type" content={type} />
      <meta property="og:url" content={url} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />
      {/* Twitter */}
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content={url} />
      <meta property="twitter:title" content={title} />
      <meta property="twitter:description" content={description} />
      <meta property="twitter:image" content={image} />
      {/* Additional meta tags */}
      <meta name="application-name" content="AthenaAI" />
      <meta name="apple-mobile-web-app-title" content="AthenaAI" />
      <meta name="theme-color" content="#ffffff" />{" "}
      {/* Adjust color as needed */}
      {/* Favicon and App Icons - Replace with actual paths */}
      <link rel="icon" href="/favicon.ico" />
      <link rel="apple-touch-icon" href="/apple-touch-icon.png" />
      {/* Canonical URL */}
      <link rel="canonical" href={url} />
      {/* Structured Data */}
      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "https://schema.org",
          "@type": "WebApplication",
          name: "AthenaAI",
          description: description,
          url: url,
          applicationCategory: "EducationalApplication",
          offers: {
            "@type": "AggregateOffer",
            offers: [
              {
                "@type": "Offer",
                name: "Starter",
                price: "0",
                priceCurrency: "USD",
                description: "Free plan with unlimited decks and cards",
              },
              {
                "@type": "Offer",
                name: "Plus",
                price: "4.99",
                priceCurrency: "USD",
                description: "Advanced features with priority support",
              },
              {
                "@type": "Offer",
                name: "Pro",
                price: "9.99",
                priceCurrency: "USD",
                description: "Most intelligent AI model with unlimited PDFs",
              },
            ],
          },
        })}
      </script>
    </Helmet>
  );
};

export default AthenaSEO;
