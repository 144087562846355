import React, { useState, useRef, useEffect, useCallback } from "react";
import {
  FaPaperPlane,
  FaUserAstronaut,
  FaUser,
  FaLightbulb,
} from "react-icons/fa";
import { ChevronLeft } from "lucide-react";

import { sendChatMessage } from "../services/apiCalls";

const ChatWindow = ({ currentFlashcard, toggleChatSidebar }) => {
  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState("");
  const [conversationId, setConversationId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [suggestions, setSuggestions] = useState([]);
  const messagesEndRef = useRef(null);
  const previousFlashcardRef = useRef();

  const scrollToBottom = useCallback(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, []);

  useEffect(scrollToBottom, [messages]);

  const handleSendMessage = useCallback(
    async (message, shouldDisplay = true) => {
      if (!message.trim()) return;

      setIsLoading(true);
      setError(null);

      if (shouldDisplay) {
        setMessages((prevMessages) => [
          ...prevMessages,
          { role: "user", content: message },
        ]);
      }

      try {
        const response = await sendChatMessage(
          message,
          conversationId,
          messages
        );
        const data = JSON.parse(response.response);

        if (shouldDisplay) {
          setMessages((prevMessages) => [
            ...prevMessages,
            { role: "system", content: data.answer },
          ]);
        }

        setSuggestions(data.suggestions || []);

        if (!conversationId) {
          setConversationId(response.conversation_id);
        }
      } catch (err) {
        setError("Failed to send message. Please try again.");
      } finally {
        setIsLoading(false);
      }
    },
    [conversationId, messages]
  );

  useEffect(() => {
    if (currentFlashcard && currentFlashcard !== previousFlashcardRef.current) {
      const flashcardMessage = `New flashcard context:
Question: "${currentFlashcard.question}"
Answer: "${currentFlashcard.answer}"
Please provide an explanation and give me exactly 3 suggestions for further study.`;

      handleSendMessage(flashcardMessage, false);
      previousFlashcardRef.current = currentFlashcard;
    }
  }, [currentFlashcard, handleSendMessage]);

  const handleSuggestionClick = useCallback(
    (suggestion) => {
      handleSendMessage(suggestion);
    },
    [handleSendMessage]
  );

  const handleInputSend = useCallback(() => {
    if (inputMessage.trim()) {
      handleSendMessage(inputMessage);
      setInputMessage("");
    }
  }, [inputMessage, handleSendMessage]);

  return (
    <div className="flex flex-col h-full text-zinc-100">
      {/* Chat Messages */}
      <div className="flex-grow overflow-y-auto p-4 text-sm space-y-4">
        {messages.map((message, index) => (
          <div
            key={index}
            className={`flex ${
              message.role === "user" ? "justify-end" : "justify-start"
            }`}
          >
            <div
              className={`flex items-start space-x-2 max-w-[90%] ${
                message.role === "user" ? "flex-row-reverse" : "flex-row"
              }`}
            >
              <div
                className={`flex-shrink-0 w-8 h-8 rounded-full flex items-center justify-center ${
                  message.role === "user" ? "bg-indigo-600" : "bg-zinc-700"
                }`}
              >
                {message.role === "user" ? (
                  <FaUser className="text-white" />
                ) : (
                  <FaUserAstronaut className="text-white" />
                )}
              </div>
              <div
                className={`p-3 rounded-lg ${
                  message.role === "user"
                    ? "bg-indigo-600 text-white"
                    : "bg-zinc-700 text-zinc-100"
                }`}
              >
                {message.content}
              </div>
            </div>
          </div>
        ))}
        {isLoading && (
          <div className="flex justify-start">
            <div className="bg-zinc-700 text-zinc-100 p-3 rounded-lg">
              Thinking...
            </div>
          </div>
        )}
        {error && (
          <div className="flex justify-center">
            <div className="bg-red-600 text-white p-3 rounded-lg">{error}</div>
          </div>
        )}
        <div ref={messagesEndRef} />
      </div>

      {/* Suggestions */}
      {suggestions.length > 0 && (
        <div className="p-4 bg-zinc-800 border-t border-zinc-700">
          <div className="flex items-center space-x-2 mb-2">
            <FaLightbulb className="text-yellow-500" />
            <span className="text-sm font-medium text-zinc-300">
              Suggestions:
            </span>
          </div>
          <div className="flex flex-wrap gap-2">
            {suggestions.map((suggestion, index) => (
              <button
                key={index}
                onClick={() => handleSuggestionClick(suggestion)}
                className="px-3 py-1 text-xs bg-zinc-700 text-zinc-300 rounded-full hover:bg-zinc-600 transition duration-300"
              >
                {suggestion}
              </button>
            ))}
          </div>
        </div>
      )}

      {/* Input Area */}
      <div className="p-4 bg-zinc-800 border-t border-zinc-700">
        <div className="flex items-center space-x-2">
          <button
            onClick={toggleChatSidebar}
            className="p-2 rounded-lg bg-zinc-700 text-zinc-300 hover:bg-zinc-600 transition duration-300 md:hidden"
          >
            <ChevronLeft size={20} />
          </button>
          <input
            type="text"
            value={inputMessage}
            onChange={(e) => setInputMessage(e.target.value)}
            onKeyPress={(e) => e.key === "Enter" && handleInputSend()}
            className="flex-grow p-2 rounded-lg bg-zinc-700 text-zinc-100 focus:outline-none focus:ring-2 focus:ring-indigo-500"
            placeholder="Type your message..."
            disabled={isLoading}
          />
          <button
            onClick={handleInputSend}
            className="p-2 rounded-lg bg-indigo-600 text-white hover:bg-indigo-700 transition duration-300 disabled:opacity-50"
            disabled={isLoading}
          >
            <FaPaperPlane />
          </button>
        </div>
      </div>
    </div>
  );
};

export default ChatWindow;
