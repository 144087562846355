import React from "react";

const LoadingSpinner = () => {
  return (
    <div className="flex flex-col items-center justify-center h-64">
      <div className="relative">
        {[...Array(3)].map((_, index) => (
          <div
            key={index}
            className={`absolute inset-0 rounded-full border-2 border-accent-indigo opacity-0 animate-ping`}
            style={{
              animationDelay: `${index * 0.5}s`,
              animationDuration: "2.5s",
            }}
          ></div>
        ))}
        <div className="w-16 h-16 rounded-full bg-accent-indigo/20 flex items-center justify-center">
          <div className="w-8 h-8 rounded-full bg-accent-indigo animate-pulse"></div>
        </div>
      </div>
      <p className="mt-6 text-lg text-zinc-400 font-medium animate-pulse">
        Loading...
      </p>
    </div>
  );
};

export default LoadingSpinner;
