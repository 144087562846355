export const HtmlContent = ({ htmlContent }) => {
  return (
    <div
      className="text-white"
      dangerouslySetInnerHTML={{ __html: htmlContent }}
    ></div>
  );
};

export const htmlToString = (htmlString) => htmlString.replace(/<[^>]*>/g, "");
