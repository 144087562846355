import { useState, useRef } from "react";
import { v4 as uuidv4 } from "uuid";
import { notifyError } from "./Notification";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

export default function CreateCardModal({ setSelectedDeck }) {
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");
  const questionQuillRef = useRef(null);
  const answerQuillRef = useRef(null);

  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["image"],
    ],
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "list",
    "bullet",
    "link",
    "image",
  ];

  return (
    <div className="overflow-y-scroll max-h-screen">
      <div className="mt-2">
        <div>
          <label
            htmlFor="question"
            className="block text-xl font-medium text-white"
          >
            Question
          </label>
          <ReactQuill
            ref={questionQuillRef}
            theme="snow"
            value={question}
            onChange={setQuestion}
            modules={modules}
            formats={formats}
            placeholder="Enter card question..."
          />
        </div>
        <div className="mt-4">
          <label
            htmlFor="answer"
            className="block mt-4 font-medium text-xl text-white"
          >
            Answer
          </label>
          <ReactQuill
            ref={answerQuillRef}
            theme="snow"
            value={answer}
            onChange={setAnswer}
            modules={modules}
            formats={formats}
            placeholder="Enter answer..."
          />
        </div>
      </div>
      <div className="mt-4">
        <button
          type="button"
          className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
          onClick={() => {
            if (!question || !answer) {
              notifyError("Please fill out all fields");
              return;
            }
            setSelectedDeck((prevDeck) => {
              return {
                ...prevDeck,
                cards: [
                  {
                    id: uuidv4(),
                    question: question,
                    answer: answer,
                  },
                  ...prevDeck.cards,
                ],
              };
            });
            setQuestion("");
            setAnswer("");
            //closeModal();
          }}
        >
          Create
        </button>
      </div>
    </div>
  );
}
