import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useDecks } from "../services/DeckProvider";
import { findLastUsedDeck, handleDeckClick } from "../helpers/utils";
import DecksSidebar from "../components/Practice/DecksSidebar";
import MainContent from "../components/Practice/MainContent";
import ChatSidebar from "../components/Practice/ChatSidebar";
import MobileOverlay from "../components/Practice/MobileOverlay";
import LoadingScreen from "../components/Practice/LoadingScreen";
import ErrorScreen from "../components/Practice/ErrorScreen";
import CreateDeckModal from "../components/CreateDeckModal";

const PracticePage = () => {
  const [showCreateDeckModal, setShowCreateDeckModal] = useState(false);
  const [showError, setShowError] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [selectedDeck, setSelectedDeck] = useState(null);
  const [isDecksSidebarCollapsed, setIsDecksSidebarCollapsed] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [isMobileOverlayVisible, setIsMobileOverlayVisible] = useState(false);
  const [isChatSidebarOpen, setIsChatSidebarOpen] = useState(false);
  const [currentFlashcard, setCurrentFlashcard] = useState(null);

  const { decks, isLoading, error } = useDecks();
  const location = useLocation();
  const [lastUsedDecks, setLastUsedDecks] = useState(() => {
    const storedDecks = localStorage.getItem("lastUsedDecks");
    return storedDecks ? JSON.parse(storedDecks) : [];
  });

  useEffect(() => {
    initializeSelectedDeck();
  }, [isLoading, decks, location.state?.id, lastUsedDecks]);

  useEffect(() => {
    updateLastUsedDeck();
  }, [selectedDeck]);

  const initializeSelectedDeck = () => {
    if (decks.length > 0 && !selectedDeck?.id) {
      const { id } = location.state || {};
      let newSelectedDeck;

      if (id) {
        newSelectedDeck = decks.find((deck) => deck.id === id) || decks[0];
      } else if (lastUsedDecks.length > 0) {
        newSelectedDeck = findLastUsedDeck(decks, lastUsedDecks[0]) || decks[0];
      } else {
        newSelectedDeck = decks[0];
      }

      setSelectedDeck(newSelectedDeck);
    }
  };

  const updateLastUsedDeck = () => {
    if (selectedDeck?.id && selectedDeck?.name) {
      handleDeckClick(selectedDeck.id, selectedDeck.name, setLastUsedDecks);
    }
  };

  const toggleDecksSidebar = () => {
    setIsDecksSidebarCollapsed(!isDecksSidebarCollapsed);
    setIsMobileOverlayVisible(isDecksSidebarCollapsed);
  };

  const toggleChatSidebar = () => {
    setIsChatSidebarOpen(!isChatSidebarOpen);
  };

  const filteredDecks =
    decks?.filter((deck) =>
      deck.name.toLowerCase().includes(searchTerm.toLowerCase())
    ) || [];

  if (isLoading) return <LoadingScreen />;
  if (error) return <ErrorScreen error={error} />;

  return (
    <div className="h-[calc(100vh-140px)] bg-zinc-800 text-sm-mobile md:text-sm-desktop overflow-hidden">
      <div className="flex h-full bg-zinc-900 text-zinc-100 overflow-hidden">
        <DecksSidebar
          isDecksSidebarCollapsed={isDecksSidebarCollapsed}
          toggleDecksSidebar={toggleDecksSidebar}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          filteredDecks={filteredDecks}
          selectedDeck={selectedDeck}
          setSelectedDeck={setSelectedDeck}
        />

        <MainContent
          isDecksSidebarCollapsed={isDecksSidebarCollapsed}
          toggleDecksSidebar={toggleDecksSidebar}
          toggleChatSidebar={toggleChatSidebar}
          showSuccess={showSuccess}
          setShowSuccess={setShowSuccess}
          decks={decks}
          selectedDeck={selectedDeck}
          setSelectedDeck={setSelectedDeck}
          setCurrentFlashcard={setCurrentFlashcard}
          setShowCreateDeckModal={setShowCreateDeckModal}
        />

        <ChatSidebar
          isChatSidebarOpen={isChatSidebarOpen}
          toggleChatSidebar={toggleChatSidebar}
          currentFlashcard={currentFlashcard}
        />

        <MobileOverlay
          isDecksSidebarCollapsed={isDecksSidebarCollapsed}
          isChatSidebarOpen={isChatSidebarOpen}
          setIsDecksSidebarCollapsed={setIsDecksSidebarCollapsed}
          setIsChatSidebarOpen={setIsChatSidebarOpen}
        />

        <CreateDeckModal
          isOpen={showCreateDeckModal}
          setIsOpen={setShowCreateDeckModal}
          setSelectedDeck={setSelectedDeck}
        />
      </div>
    </div>
  );
};

export default PracticePage;
