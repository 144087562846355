import React from "react";
import { ArrowRight, Sparkles, Brain, Zap, Target } from "lucide-react";
import { useNavigate } from "react-router-dom";
import HeroImage from "../../images/HeroImage.jpg"; // Adjust the import path as needed

const HeroSection = () => {
  const navigate = useNavigate();

  return (
    <div className="relative bg-gradient-to-b from-gray-900 to-black text-white overflow-hidden">
      {/* Background image */}
      <div className="absolute inset-0 z-0">
        <img
          src={HeroImage}
          alt="Hero background"
          className="w-full h-full object-cover object-center opacity-20"
        />
      </div>

      {/* Animated overlay */}
      <div className="absolute inset-0 overflow-hidden z-10">
        <div className="absolute inset-0 bg-grid-white/[0.05] bg-[length:30px_30px]" />
        <div className="absolute inset-0 bg-gradient-to-t from-black via-transparent to-transparent" />
        {[...Array(50)].map((_, i) => (
          <div
            key={i}
            className="absolute bg-blue-500 opacity-20 rounded-full blur-xl animate-float"
            style={{
              left: `${Math.random() * 100}%`,
              top: `${Math.random() * 100}%`,
              width: `${Math.random() * 50 + 10}px`,
              height: `${Math.random() * 50 + 10}px`,
              animationDuration: `${Math.random() * 10 + 5}s`,
              animationDelay: `${Math.random() * 5}s`,
            }}
          />
        ))}
      </div>

      <div className="container mx-auto px-4 py-20 sm:py-32 relative z-20">
        <div className="flex flex-col lg:flex-row items-center justify-between">
          <div className="lg:w-1/2 mb-10 lg:mb-0">
            <h1 className="text-4xl sm:text-5xl lg:text-6xl font-extrabold leading-tight mb-6">
              Revolutionize Your Learning with{" "}
              <span className="text-transparent bg-clip-text bg-gradient-to-r from-indigo-400 via-purple-500 to-pink-500">
                Athena AI
              </span>
            </h1>
            <p className="text-xl text-gray-300 mb-8">
              Unlock the power of personalized learning. Athena adapts to your
              style, transforming how you absorb and retain knowledge.
              Experience the future of education today.
            </p>
            <div className="flex flex-col sm:flex-row gap-4 mb-12">
              <button
                onClick={() => navigate("/login")}
                className="bg-gradient-to-r from-indigo-600 via-purple-600 to-pink-500 text-white px-8 py-3 rounded-full text-lg font-semibold hover:opacity-90 transition-all duration-300 transform hover:scale-105 flex items-center justify-center"
              >
                Start now for free
                <ArrowRight className="ml-2" size={20} />
              </button>
              <button className="border border-purple-400 text-purple-400 px-8 py-3 rounded-full text-lg font-semibold hover:bg-purple-400 hover:text-white transition-colors duration-300">
                Watch Demo
              </button>
            </div>
          </div>
          <div className="lg:w-1/2">
            <div className="bg-gray-800 bg-opacity-50 backdrop-blur-sm rounded-xl p-8 shadow-lg">
              <h2 className="text-2xl font-bold mb-6 text-center">
                Why Choose Athena?
              </h2>
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
                {[
                  {
                    icon: Brain,
                    title: "AI-Powered Learning",
                    description:
                      "Personalized study plans tailored to your needs",
                  },
                  {
                    icon: Zap,
                    title: "Efficient Progress",
                    description: "Learn faster with adaptive techniques",
                  },
                  {
                    icon: Target,
                    title: "Goal-Oriented",
                    description: "Set and achieve your learning objectives",
                  },
                  {
                    icon: Sparkles,
                    title: "Engaging Content",
                    description: "Interactive flashcards and quizzes",
                  },
                ].map((item, index) => (
                  <div key={index} className="flex items-start">
                    <item.icon
                      className="text-indigo-400 mr-3 flex-shrink-0"
                      size={24}
                    />
                    <div>
                      <h3 className="font-semibold mb-1">{item.title}</h3>
                      <p className="text-gray-400 text-sm">
                        {item.description}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
