import React from "react";

const DataCollectionToggle = ({ collectData, onToggle }) => (
  <div className="bg-zinc-700 rounded-lg p-4 shadow-md">
    <div className="flex items-center justify-between mb-2">
      <span className="text-lg font-semibold">Collect Data</span>
      <button
        onClick={() => onToggle(!collectData)}
        className={`relative inline-flex items-center h-6 rounded-full w-11 transition-colors focus:outline-none ${
          collectData ? "bg-indigo-600" : "bg-gray-600"
        }`}
      >
        <span
          className={`inline-block w-4 h-4 transform transition-transform bg-white rounded-full ${
            collectData ? "translate-x-6" : "translate-x-1"
          }`}
        />
      </button>
    </div>
    <p className="text-sm text-gray-300">
      Allow us to collect anonymous usage data to improve your experience.
    </p>
  </div>
);

export default DataCollectionToggle;
