import React from "react";
import Features from "../components/LandingPage.js/Features";
import HeroSection from "../components/LandingPage.js/HeroSection";
import Showcase from "../components/LandingPage.js/Showcase";
import FinalCTA from "../components/LandingPage.js/FinalCTA";
import Footer from "../components/LandingPage.js/Footer";
import PricingSection from "../components/LandingPage.js/PricingPlan";
import { useNavigate } from "react-router-dom";

/**
 * Renders the Landing Page component.
 *
 * @returns {JSX.Element} The Landing Page component.
 */
function LandingPage() {
  const navigate = useNavigate();
  return (
    <div className="w-full bg-gradient-to-b from-gray-900 to-black text-white">
      <div id="get-started" className="bg-background w-full">
        <HeroSection />
      </div>
      <div id="features">
        <Features />
      </div>
      <div id="showcase">
        <Showcase />
      </div>
      <div id="pricing">
        <PricingSection loginWithRedirect={() => navigate("/login")} />
      </div>
      <div id="try-now">
        <FinalCTA loginWithRedirect={() => navigate("/login")} />
      </div>
      <Footer />
    </div>
  );
}

export default LandingPage;
