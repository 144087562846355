import React, { useEffect } from "react";
import {
  ErrorBoundary as ReactErrorBoundary,
  useErrorBoundary,
} from "react-error-boundary";

function ErrorFallback({ error, resetErrorBoundary }) {
  return (
    <div className="min-h-screen flex items-center justify-center bg-zinc-900">
      <div className="bg-zinc-800 p-8 rounded-lg shadow-xl text-center">
        <h1 className="text-2xl font-bold text-red-500 mb-4">
          Oops! Something went wrong.
        </h1>
        <p className="text-zinc-300 mb-4">
          We're sorry for the inconvenience. Please try refreshing the page or
          contact support if the problem persists.
        </p>
        <button
          onClick={resetErrorBoundary}
          className="bg-indigo-600 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded transition duration-300"
        >
          Try again
        </button>
      </div>
    </div>
  );
}

export function ErrorBoundary({ children }) {
  useEffect(() => {
    const originalConsoleError = console.error;
    console.error = (...args) => {
      if (args[0]?.includes("Error: Uncaught [")) {
        return;
      }
      originalConsoleError.apply(console, args);
    };

    return () => {
      console.error = originalConsoleError;
    };
  }, []);

  return (
    <ReactErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        // Reset the state of your app so the error doesn't happen again
      }}
      onError={(error, info) => {
        // Log the error (optional)
        // console.log('Caught an error:', error, info);
      }}
    >
      {children}
    </ReactErrorBoundary>
  );
}

// Custom hook to use inside components for manual error throwing
export function useErrorHandler() {
  return useErrorBoundary();
}

export default ErrorBoundary;
