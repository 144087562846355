import React from "react";
import { FiSave } from "react-icons/fi";

const SaveSettingsButton = ({ isEnabled, onSave }) => (
  <button
    onClick={onSave}
    className={`flex items-center justify-center w-full ${
      isEnabled
        ? "bg-green-600 hover:bg-green-700"
        : "bg-gray-600 cursor-not-allowed"
    } text-white font-bold py-3 px-4 rounded-md transition duration-300 shadow-md`}
    disabled={!isEnabled}
  >
    <FiSave className="mr-2" />
    Save Settings
  </button>
);

export default SaveSettingsButton;
