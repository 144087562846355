import React, { useState, useEffect } from "react";

const ComingSoon = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(true);
    }, 100);
    return () => clearTimeout(timer);
  }, []);

  return (
    <div
      className={`flex items-center justify-center min-h-screen bg-gradient-to-r from-zinc-800 via-gray-900 to-black text-white transition-opacity duration-1000 ${
        isVisible ? "opacity-100" : "opacity-0"
      }`}
    >
      <div className="text-center">
        <h1 className="text-6xl font-bold mb-4 animate-bounce">Coming Soon</h1>
        <p className="text-xl mb-4">
          We are working hard to bring you something amazing!
        </p>
        <div className="flex justify-center">
          <svg
            className="animate-spin h-10 w-10 mr-3 border-t-4 border-white rounded-full"
            viewBox="0 0 24 24"
          ></svg>
        </div>
      </div>
    </div>
  );
};

const Explore = ({ session, user }) => {
  return (
    <div>
      <ComingSoon />
    </div>
  );
};

export default Explore;
