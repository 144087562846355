import React, { useState, useRef, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import { notifyError, notifySuccess } from "../../components/Notification";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useDecks } from "../../services/DeckProvider";
import { FaTimes } from "react-icons/fa";
import { useSaveDeck } from "../../services/apiCalls";

const CardEditor = ({
  setSelectedDeck,
  selectedCard,
  setIsEditing,
  setSelectedCard,
  isEditing,
}) => {
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");
  const [showError, setShowError] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const questionQuillRef = useRef(null);
  const answerQuillRef = useRef(null);
  const { decks } = useDecks();
  const { saveDeck } = useSaveDeck();

  useEffect(() => {
    if (isEditing.mode === "edit" && selectedCard) {
      setQuestion(selectedCard.question);
      setAnswer(selectedCard.answer);
    } else {
      setQuestion("");
      setAnswer("");
    }
  }, [isEditing.mode, selectedCard]);

  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline"],
      [{ list: "ordered" }, { list: "bullet" }],
    ],
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "list",
    "bullet",
    "link",
  ];

  const handleSaveCard = () => {
    if (!question || !answer) {
      notifyError("Please fill out all fields");
      return;
    }

    const updatedCard = {
      id: isEditing.mode === "edit" ? selectedCard.id : uuidv4(),
      question: question,
      answer: answer,
    };

    setSelectedDeck((prevDeck) => {
      const updatedDeck = {
        ...prevDeck,
        cards:
          isEditing.mode === "edit"
            ? prevDeck.cards.map((card) =>
                card.id === updatedCard.id ? updatedCard : card
              )
            : [...prevDeck.cards, updatedCard],
      };
      saveDeck(updatedDeck, setSelectedDeck, setShowError, setShowSuccess);
      return updatedDeck;
    });

    setSelectedCard(updatedCard);
    setIsEditing(false);
    notifySuccess(
      isEditing.mode === "edit"
        ? "Card updated successfully!"
        : "Card created successfully!"
    );
  };

  const handleCancel = () => {
    setIsEditing(false);
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center p-4 z-50 overflow-y-auto backdrop-blur-sm">
      <div className="bg-zinc-800 rounded-lg shadow-xl w-full max-w-3xl max-h-[90vh] overflow-y-auto relative">
        <button
          onClick={handleCancel}
          className="absolute top-2 right-2 p-2 text-zinc-400 hover:text-zinc-200 transition-colors duration-200 bg-zinc-700 hover:bg-zinc-600 rounded-full z-10"
        >
          <FaTimes size={20} />
        </button>
        <div className="bg-zinc-700 p-4 rounded-t-lg border-b border-zinc-600">
          <h1 className="text-2xl sm:text-3xl font-bold text-zinc-100">
            {isEditing.mode === "edit" ? "Edit Card" : "Create New Card"}
          </h1>
        </div>
        <div className="p-6">
          <div className="space-y-6">
            <div className="bg-zinc-700 p-4 rounded-lg">
              <label
                htmlFor="question"
                className="block text-lg sm:text-xl font-medium text-zinc-200 mb-2"
              >
                Question
              </label>
              <ReactQuill
                ref={questionQuillRef}
                theme="snow"
                value={question}
                onChange={setQuestion}
                modules={modules}
                formats={formats}
                placeholder="Enter card question..."
                className="bg-zinc-600 text-zinc-100 rounded-md"
              />
            </div>
            <div className="bg-zinc-700 p-4 rounded-lg">
              <label
                htmlFor="answer"
                className="block text-lg sm:text-xl font-medium text-zinc-200 mb-2"
              >
                Answer
              </label>
              <ReactQuill
                ref={answerQuillRef}
                theme="snow"
                value={answer}
                onChange={setAnswer}
                modules={modules}
                formats={formats}
                placeholder="Enter answer..."
                className="bg-zinc-600 text-zinc-100 rounded-md"
              />
            </div>
            <div className="flex flex-col sm:flex-row justify-end space-y-2 sm:space-y-0 sm:space-x-2 mt-6 pt-4 border-t border-zinc-700">
              <button
                type="button"
                className="px-4 py-2 bg-zinc-600 text-zinc-100 rounded-md hover:bg-zinc-500 focus:outline-none focus:ring-2 focus:ring-zinc-400 focus:ring-offset-2 transition duration-300 ease-in-out"
                onClick={handleCancel}
              >
                Cancel
              </button>
              <button
                type="button"
                className="px-4 py-2 bg-blue-600 text-zinc-100 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition duration-300 ease-in-out"
                onClick={handleSaveCard}
              >
                {isEditing.mode === "edit" ? "Save Changes" : "Create Card"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardEditor;
