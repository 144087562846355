import React, { useState, useCallback, useEffect } from "react";
import {
  MDXEditor,
  headingsPlugin,
  listsPlugin,
  quotePlugin,
  thematicBreakPlugin,
  linkPlugin,
  linkDialogPlugin,
  imagePlugin,
  tablePlugin,
  codeBlockPlugin,
  UndoRedo,
  BoldItalicUnderlineToggles,
  BlockTypeSelect,
  toolbarPlugin,
} from "@mdxeditor/editor";
import { Edit3, Save, Trash2, Eye } from "lucide-react";
import Button from "../../components/Button";
import EditableHeader from "../../components/EditableHeader";
import Markdown from "react-markdown";
import "@mdxeditor/editor/style.css";

const DocumentEditor = ({ document, onSave, onDelete }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [content, setContent] = useState(document?.content || "");

  const handleEdit = () => {
    setIsEditing(true);
    console.log(content);
  };

  const handleSave = () => {
    onSave({ ...document, content });
    setIsEditing(false);
  };

  const handleDelete = () => {
    onDelete(document.id);
  };

  const updateDocumentName = (name) => {
    onSave({ ...document, title: name });
  };

  const handleEditorChange = useCallback((newContent) => {
    setContent(newContent);
  }, []);

  useEffect(() => {
    if (document) {
      setContent(document.content || "");
      setIsEditing(document.id === "new");
    }
  }, [document]);

  if (!document) {
    return (
      <div className="flex items-center justify-center h-full text-zinc-400">
        <p>Select a document to view or edit</p>
      </div>
    );
  }

  return (
    <>
      <div className="flex items-center justify-between p-4 bg-zinc-800 border-b border-zinc-700">
        <div className="flex-1 mr-4">
          <EditableHeader
            text={document.title}
            updateDeckName={updateDocumentName}
          />
        </div>
        <div className="flex space-x-2">
          {isEditing ? (
            <Button
              onClick={handleSave}
              variant="outline"
              size="sm"
              icon={Save}
            >
              Save
            </Button>
          ) : (
            <Button
              onClick={handleEdit}
              variant="outline"
              size="sm"
              icon={Edit3}
            >
              Edit
            </Button>
          )}
          <Button
            onClick={handleDelete}
            variant="outline"
            size="sm"
            icon={Trash2}
          >
            Delete
          </Button>
        </div>
      </div>

      <div className="flex-1 overflow-hidden bg-zinc-800 p-4">
        <div className="h-full">
          {isEditing ? (
            <MDXEditor
              markdown={document.content}
              onChange={handleEditorChange}
              plugins={[
                toolbarPlugin({
                  toolbarContents: () => (
                    <>
                      {" "}
                      <UndoRedo />
                      <BoldItalicUnderlineToggles />
                      <BlockTypeSelect />
                    </>
                  ),
                }),
                headingsPlugin(),
                listsPlugin(),
                quotePlugin(),
                thematicBreakPlugin(),
                linkPlugin(),
                linkDialogPlugin(),
                imagePlugin(),
                tablePlugin(),
                codeBlockPlugin(),
              ]}
              className="h-full bg-white text-zinc-800 overflow-y-auto rounded-lg p-4 focus:outline-none focus:ring-2 focus:ring-accent-indigo"
            />
          ) : (
            <div className="prose dark:prose-invert h-full text-gray-800 overflow-y-auto bg-white rounded-lg p-4">
              <Markdown>{document.content}</Markdown>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default DocumentEditor;
