import React, { useEffect, useState } from "react";
import { improvedRateAndAdvance } from "../helpers/learn_algorithm";
import OpenAI from "openai";
import { LoadingIndicator } from "./LoadingAnimations";
import SimpleModal from "./SimpleModal";
import { Eye, Info, ChevronRight, ChevronLeft } from "lucide-react";
import { HtmlContent } from "../services/htmlrender";
import {
  Book,
  FileText,
  List,
  Lightbulb,
  Link,
  ExternalLink,
  Bookmark,
} from "lucide-react";
import { useDecks } from "../services/DeckProvider";

const infobox_schema = {
  title: "InfoBox Content",
  description: "Schema for the content displayed in the InfoBox component",
  type: "object",
  properties: {
    definition: {
      type: "string",
      description: "A clear and concise definition of the topic",
    },
    summary: {
      type: "string",
      description: "A brief summary or overview of the topic",
    },
    keypoints: {
      type: "array",
      description:
        "A list of strings key points or important facts about the topic",
      items: {
        type: "string",
      },
      minItems: 1,
    },
  },
  required: ["definition", "summary", "keypoints"],
  additionalProperties: false,
};

const InfoBox = ({ isOpen, setIsOpen, text }) => {
  const renderContent = (data) => {
    if (!data || Object.keys(data).length === 0) {
      return (
        <div className="flex items-center justify-center h-32">
          <p className="text-zinc-400 text-center">No information available.</p>
        </div>
      );
    }

    const sections = [
      { key: "definition", title: "Definition", icon: Book },
      { key: "summary", title: "Summary", icon: FileText },
      { key: "keypoints", title: "Key Points", icon: List },
      { key: "examples", title: "Examples", icon: Lightbulb },
      { key: "related", title: "Related Concepts", icon: Link },
      { key: "sources", title: "Sources", icon: ExternalLink },
    ];

    return (
      <div className="space-y-4">
        {sections.map(({ key, title, icon: Icon }) => {
          if (!data[key]) return null;
          return (
            <div
              key={key}
              className="bg-zinc-700/50 p-4 rounded-lg backdrop-blur-sm"
            >
              <h3 className="text-indigo-300 font-semibold flex items-center mb-2 text-sm">
                <Icon className="mr-2" size={18} /> {title}
              </h3>
              {key === "keypoints" ||
              key === "examples" ||
              key === "related" ? (
                <ul className="list-none space-y-2">
                  {data[key].map((item, index) => (
                    <li key={index} className="flex items-start">
                      <Bookmark
                        className="text-purple-400 mr-2 flex-shrink-0 mt-1"
                        size={14}
                      />
                      <span className="text-zinc-200 text-sm">{item}</span>
                    </li>
                  ))}
                </ul>
              ) : (
                <p className="text-zinc-200 text-sm">{data[key]}</p>
              )}
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <SimpleModal
      mode="dark"
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      closeButton={true}
      className="max-h-full flex items-center justify-center px-4 sm:px-6"
    >
      <div className="bg-gradient-to-br from-zinc-800 to-zinc-900 p-8 rounded-xl w-full max-w-4xl mx-auto shadow-2xl border border-zinc-700">
        <div className="max-h-[calc(95vh-8rem)] overflow-y-auto custom-scrollbar pr-4">
          {text.length === 0 ? (
            <div className="flex items-center justify-center h-40">
              <LoadingIndicator />
            </div>
          ) : (
            renderContent(JSON.parse(text))
          )}
        </div>
      </div>
    </SimpleModal>
  );
};

const Flashcard = ({ selectedDeck, setSelectedDeck, setCurrentFlashcard }) => {
  const [currentFlashcardIndex, setCurrentFlashcardIndex] = useState(0);
  const [showInfo, setshowInfo] = useState(false);
  const [info, setInfo] = useState("");
  const [flip, setFlip] = useState(false);
  const api_host = process.env.REACT_APP_API_HOST;
  const { refreshDecks, saveDeck } = useDecks();

  useEffect(() => {
    setInfo("");
  }, [currentFlashcardIndex]);

  useEffect(() => {
    setCurrentFlashcard(selectedDeck.cards[currentFlashcardIndex]);
  }, [currentFlashcardIndex, selectedDeck.cards, setCurrentFlashcard]);

  useEffect(() => {
    setFlip(false);
  }, [selectedDeck]);

  const generateInfo = async (prompt) => {
    try {
      const url = `${api_host}/api/get-info`;
      const header = {
        method: "POST",
        body: JSON.stringify({ prompt }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
        redirect: "follow",
      };
      const response = await fetch(url, header);
      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }
      const data = await response.json();
      setInfo(data);
    } catch (error) {
      console.error("Error during fetch operation: ", error.message);
    }
  };

  const handleNextCard = async () => {
    if (currentFlashcardIndex < selectedDeck.cards.length - 1) {
      setCurrentFlashcardIndex((prev) => prev + 1);
      setFlip(false);
    }
  };

  const handlePreviousCard = () => {
    if (currentFlashcardIndex > 0) {
      setCurrentFlashcardIndex((prev) => prev - 1);
      setFlip(false);
    }
  };

  const NoCardAvailable = () => (
    <div className="flex justify-center items-center h-64 bg-zinc-800 rounded-xl shadow-md">
      <p className="text-base text-zinc-400 text-center">
        This deck is empty. Go to the decks page to create cards.
      </p>
    </div>
  );

  if (selectedDeck.cards?.length === 0) {
    return <NoCardAvailable />;
  }

  return (
    <div className="w-full max-w-3xl mx-auto space-y-6">
      <div className="bg-zinc-800 rounded-xl shadow-md p-6">
        <div className="flex flex-wrap items-center justify-between gap-3 mb-6">
          <button
            disabled={flip}
            className={`${
              flip
                ? "bg-zinc-600"
                : "bg-accent-indigo hover:bg-accent-indigo/90"
            } text-white text-sm font-medium px-4 py-2 rounded-lg transition duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-accent-indigo disabled:opacity-50 flex items-center`}
            onClick={() => setFlip(true)}
          >
            <Eye className="w-4 h-4 mr-2" /> Show
          </button>
          <div className="flex gap-2">
            {[
              { rating: 1, label: "Hard", color: "text-accent-rose" },
              { rating: 2, label: "Medium", color: "text-accent-amber" },
              { rating: 3, label: "Easy", color: "text-accent-emerald" },
            ].map(({ rating, label, color }) => (
              <button
                key={rating}
                disabled={!flip}
                className={`${
                  flip ? "bg-zinc-700 hover:bg-zinc-600" : "bg-zinc-600"
                } text-white text-sm font-medium px-3 py-2 rounded-lg transition duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-accent-indigo disabled:opacity-50 flex flex-col items-center justify-center w-14 h-14`}
                onClick={async () => {
                  const updatedDeck = await improvedRateAndAdvance(
                    rating,
                    selectedDeck,
                    setSelectedDeck,
                    currentFlashcardIndex,
                    setCurrentFlashcardIndex,
                    setFlip
                  );
                  await saveDeck(updatedDeck);
                  // await refreshDecks(updatedDeck);
                }}
              >
                <span>{rating}</span>
                <span className={`text-xs ${color}`}>{label}</span>
              </button>
            ))}
          </div>
          <button
            disabled={!flip}
            className={`${
              flip
                ? "bg-accent-yellow hover:bg-accent-yellow/90"
                : "bg-zinc-600"
            } text-white text-sm font-medium px-3 py-2 rounded-lg transition duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-accent-yellow disabled:opacity-50`}
            onClick={() => {
              setshowInfo(true);
              generateInfo(
                `Generate a short summary with definition, summary, and keypoints in JSON format for: ${selectedDeck.cards[currentFlashcardIndex].question} : ${selectedDeck.cards[currentFlashcardIndex].answer}`
              );
            }}
          >
            <Info className="w-4 h-4" />
          </button>
        </div>

        <div
          className={`transition duration-200 ${
            flip ? "opacity-50" : "opacity-100"
          }`}
          onClick={() => setFlip(true)}
        >
          <div className="bg-zinc-700 rounded-xl p-6 cursor-pointer min-h-[150px]">
            <div className="text-zinc-100 text-base">
              {selectedDeck.cards && (
                <HtmlContent
                  htmlContent={
                    selectedDeck.cards[currentFlashcardIndex].question
                  }
                />
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="bg-zinc-800 border border-zinc-700 rounded-xl shadow-md overflow-hidden">
        <div
          className="min-h-[150px] p-6 cursor-pointer transition-all duration-200"
          onClick={() => setFlip(true)}
        >
          {flip ? (
            <div className="text-zinc-100 text-base">
              {selectedDeck.cards && (
                <HtmlContent
                  htmlContent={selectedDeck.cards[currentFlashcardIndex].answer}
                />
              )}
            </div>
          ) : (
            <p className="text-zinc-400 text-center text-base">
              Click to show answer
            </p>
          )}
        </div>
      </div>

      <div className="flex justify-between mt-6">
        <button
          onClick={handlePreviousCard}
          disabled={currentFlashcardIndex === 0}
          className="text-zinc-400 hover:text-zinc-200 disabled:opacity-50 transition-colors duration-200"
        >
          <ChevronLeft className="w-6 h-6" />
        </button>
        <button
          onClick={handleNextCard}
          disabled={currentFlashcardIndex === selectedDeck.cards.length - 1}
          className="text-zinc-400 hover:text-zinc-200 disabled:opacity-50 transition-colors duration-200"
        >
          <ChevronRight className="w-6 h-6" />
        </button>
      </div>

      <InfoBox setIsOpen={setshowInfo} isOpen={showInfo} text={info} />
    </div>
  );
};

export default Flashcard;
