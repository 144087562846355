import React, { useEffect, useState } from "react";
import { Save, Trash2, Heart, ChevronRight, Search, Edit } from "lucide-react";

const EditableHeader = ({ text, updateDeckName }) => {
  const [headerText, setHeaderText] = useState(text);
  const [isEditing, setIsEditing] = useState(false);

  const handleHeaderClick = () => setIsEditing(true);
  const handleInputChange = (event) => setHeaderText(event.target.value);
  const handleInputBlur = () => {
    setIsEditing(false);
    updateDeckName(headerText);
  };
  const handleKeyDown = (event) => {
    if (event.key === "Enter") handleInputBlur();
  };

  useEffect(() => {
    setHeaderText(text);
  }, [text]);

  return (
    <div className="flex items-center justify-center bg-zinc-700 rounded-lg p-2">
      {isEditing ? (
        <input
          type="text"
          value={headerText}
          onChange={handleInputChange}
          onBlur={handleInputBlur}
          onKeyDown={handleKeyDown}
          autoFocus
          className="text-white text-center text-xl font-bold bg-zinc-600 border-b-2 border-accent-indigo outline-none rounded px-2 py-1 w-full"
        />
      ) : (
        <h1
          className="text-white flex items-center text-center text-xl font-bold cursor-pointer"
          onClick={handleHeaderClick}
        >
          {headerText}
          <Edit className="ml-2 w-4 h-4 text-zinc-400" />
        </h1>
      )}
    </div>
  );
};

export default EditableHeader;
