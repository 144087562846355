import React, { useState, useRef, useEffect } from "react";
import Button from "./Button";
import { useNavigate } from "react-router-dom";
import { FaPlus, FaMinus } from "react-icons/fa";

function AddOptionsDropdown({
  setShowCreateCardModal,
  setShowCreateDeckModal,
  selectedDeck,
  decks,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="relative" ref={dropdownRef}>
      <Button
        className="bg-zinc-700 hover:bg-zinc-600 text-white w-10 h-10 sm:w-10 sm:h-10 rounded-lg flex items-center justify-center"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className="sr-only">Menu</span>
        {isOpen ? (
          <FaMinus className="w-5 h-5 sm:w-5 sm:h-5 sm:stroke-[1.5]" />
        ) : (
          <FaPlus className="w-5 h-5 sm:w-5 sm:h-5 sm:stroke-[1.5]" />
        )}
      </Button>
      {isOpen && (
        <div
          className="absolute left-0 sm:right-0 z-10 mt-2 w-48 sm:w-56 rounded-md border border-zinc-700 bg-zinc-800 shadow-lg"
          role="menu"
        >
          <div className="p-1 sm:p-2">
            {decks?.length > 0 && (
              <>
                <button
                  onClick={() => {
                    setShowCreateCardModal({ state: true, mode: "create" });
                    setIsOpen(false);
                  }}
                  className="w-full text-left block rounded-lg px-3 sm:px-4 py-1.5 sm:py-2 text-xs sm:text-sm text-zinc-300 hover:bg-zinc-700 hover:text-white"
                  title="Create individual card"
                >
                  Create card
                </button>
                {/* <button
                  onClick={() => {
                    navigate(`/decks/${selectedDeck.id}`);
                    setIsOpen(false);
                  }}
                  className="w-full text-left block rounded-lg px-3 sm:px-4 py-1.5 sm:py-2 text-xs sm:text-sm text-zinc-300 hover:bg-zinc-700 hover:text-white"
                  title="Generate multiple cards"
                >
                  Generate cards by Topic
                </button> */}
              </>
            )}
            <button
              onClick={() => {
                setShowCreateDeckModal(true);
                setIsOpen(false);
              }}
              className="w-full text-left block rounded-lg px-3 sm:px-4 py-1.5 sm:py-2 text-xs sm:text-sm text-zinc-300 hover:bg-zinc-700 hover:text-white"
              title="Create a new deck"
            >
              Create a new deck
            </button>
            {/* <button
              onClick={() => {
                navigate(`/decks/pdf/${selectedDeck.id}`);
                setIsOpen(false);
              }}
              className="w-full text-left block rounded-lg px-3 sm:px-4 py-1.5 sm:py-2 text-xs sm:text-sm text-zinc-300 hover:bg-zinc-700 hover:text-white"
              title="Create new cards from a attached document"
            >
              PDF and Text
            </button> */}
          </div>
        </div>
      )}
    </div>
  );
}

export default AddOptionsDropdown;
