import React, { useEffect, useState } from "react";

const UserSync = ({ session, children }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [userData, setUserData] = useState(null);
  const API_HOST = process.env.REACT_APP_API_HOST;

  useEffect(() => {
    const syncUser = async () => {
      if (session?.user) {
        try {
          setIsLoading(true);
          const response = await fetch(`${API_HOST}/api/users/sync-user`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              id: session.user.id,
              email: session.user.email,
              name: session.user.user_metadata?.name || "",
              membership: session.user.user_metadata?.membership || "free",
              collect_data: session.user.user_metadata?.collect_data ?? true,
            }),
          });

          if (!response.ok) {
            throw new Error("Network response was not ok");
          }

          const data = await response.json();
          setUserData(data.user);
        } catch (error) {
          console.error("Error syncing user:", error);
        } finally {
          setIsLoading(false);
        }
      } else {
        setIsLoading(false);
      }
    };

    syncUser();
  }, [session]);

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        Loading...
      </div>
    );
  }

  return children(userData);
};

export default UserSync;
