import React from "react";
import { HtmlContent } from "../../services/htmlrender";
import { ArrowUpRight } from "lucide-react";

const CardGrid = ({ filteredCards, setSelectedCard, setIsCardDetailOpen }) => {
  return (
    <div className="flex-1 overflow-y-auto p-4">
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
        {filteredCards.map((card) => (
          <div
            key={card.id}
            className="
              bg-zinc-800 hover:bg-zinc-700
              p-3 rounded-lg cursor-pointer transition-all duration-200 
              flex flex-col justify-between shadow-md 
              transform hover:-translate-y-1 hover:shadow-lg
              border border-zinc-700 hover:border-accent-indigo
            "
            style={{ height: "120px" }}
            onClick={() => {
              setSelectedCard(card);
              setIsCardDetailOpen(true);
            }}
          >
            <div className="overflow-hidden flex-grow">
              <p className="font-medium mb-1 line-clamp-3 text-sm text-zinc-100">
                <HtmlContent htmlContent={card.question} />
              </p>
            </div>
            <div className="flex justify-between items-center mt-2 text-xs text-zinc-400">
              <span>Click to view</span>
              <ArrowUpRight className="w-4 h-4 text-accent-indigo" />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CardGrid;
