import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useRef,
  useCallback,
} from "react";
import { supabase } from "../supabaseClient";
import { fetchDecks, fetchMembership, getUser } from "./apiCalls";

const DecksContext = createContext();

export const DecksProvider = ({ children }) => {
  const [session, setSession] = useState(null);
  const [decks, setDecks] = useState([]);
  const [userData, setUserData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [membership, setMembership] = useState("Trial");
  const [username, setUsername] = useState("");
  const initialFetchDone = useRef(false);

  useEffect(() => {
    const setUpSupabase = async () => {
      const {
        data: { session },
      } = await supabase.auth.getSession();
      setSession(session);
      const {
        data: { subscription },
      } = supabase.auth.onAuthStateChange((_event, session) => {
        setSession(session);
      });
      return () => subscription.unsubscribe();
    };
    setUpSupabase();
  }, []);

  const fetchUserData = useCallback(async () => {
    if (!session?.user || isLoading) return;
    setIsLoading(true);
    try {
      const [userDataResult, userDecks, userMembership] = await Promise.all([
        getUser(session.user.id),
        fetchDecks(session.user.id, session.access_token),
        fetchMembership(session.user.id, session.access_token),
      ]);
      setUsername(userDataResult[0]?.name || "");
      setUserData(session.user);
      setDecks(userDecks);
      setMembership(userMembership);
      initialFetchDone.current = true;
    } catch (error) {
      console.error("Error fetching user data:", error);
    } finally {
      setIsLoading(false);
    }
  }, [session, isLoading]);

  useEffect(() => {
    if (session && !initialFetchDone.current) {
      fetchUserData();
    }
  }, [session, fetchUserData]);

  const refreshDecks = useCallback(
    async (updatedDeck) => {
      if (!session?.user) return;

      // Optimistically update the local state
      if (updatedDeck) {
        setDecks((currentDecks) =>
          currentDecks.map((deck) =>
            deck.id === updatedDeck.id ? { ...deck, ...updatedDeck } : deck
          )
        );
      }

      // Fetch the latest data from the server
      try {
        const latestDecks = await fetchDecks(
          session.user.id,
          session.access_token
        );
        setDecks(latestDecks);
        return latestDecks;
      } catch (error) {}
    },
    [session]
  );

  const saveDeck = useCallback(
    async (deckToSave) => {
      if (!session?.user) return;

      try {
        const url = `${process.env.REACT_APP_API_HOST}/deck/${deckToSave.id}`;
        const response = await fetch(url, {
          method: "PUT",
          body: JSON.stringify(deckToSave),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${session.access_token}`,
          },
        });

        if (!response.ok) {
          throw new Error("Failed to update deck");
        }

        const data = await response.json();
        const updatedDeck = data.response.data[0];

        // Refresh decks after saving
        await refreshDecks(updatedDeck);

        return updatedDeck;
      } catch (error) {
        console.error("Error saving deck:", error);
        throw error;
      }
    },
    [session, refreshDecks]
  );

  const contextValue = {
    decks,
    user: userData,
    session,
    setDecks,
    refreshDecks,
    saveDeck,
    accessToken: session?.access_token,
    isLoading,
    membership,
    username,
  };

  return (
    <DecksContext.Provider value={contextValue}>
      {children}
    </DecksContext.Provider>
  );
};

export const useDecks = () => useContext(DecksContext);
