import React from "react";

const UsernameInput = ({ username, onChange }) => (
  <div className="bg-zinc-700 rounded-lg p-4 shadow-md">
    <label htmlFor="username" className="block text-lg font-semibold mb-2">
      Username
    </label>
    <input
      type="text"
      id="username"
      value={username || ""} // Ensure value is never undefined
      onChange={(e) => onChange(e.target.value)}
      className="w-full bg-zinc-600 rounded-md px-4 py-2 focus:outline-none focus:ring-2 focus:ring-indigo-500 transition duration-300"
    />
  </div>
);

export default UsernameInput;
