import React from "react";
import { ArrowRight } from "lucide-react";
import CTAImage from "../../images/cta_image.jpg"; // Adjust the import path as needed

const FinalCTA = ({ loginWithRedirect }) => {
  return (
    <section className="relative bg-gradient-to-b from-gray-900 to-black text-white py-20 overflow-hidden">
      {/* Background image */}
      <div className="absolute inset-0 z-0">
        <img
          src={CTAImage}
          alt="CTA background"
          className="w-full h-full object-cover object-center opacity-70"
        />
      </div>

      {/* Overlay */}
      <div className="absolute inset-0 bg-black opacity-90 z-10"></div>

      {/* Content */}
      <div className="container mx-auto px-4 relative z-20">
        <div className="max-w-3xl mx-auto text-center">
          <h2 className="text-3xl md:text-4xl lg:text-5xl font-bold mb-6">
            Ready to Unlock Your Full Potential with{" "}
            <span className="text-transparent bg-clip-text bg-gradient-to-r from-indigo-400 via-purple-500 to-pink-500">
              Athena
            </span>
            ?
          </h2>
          <p className="text-lg md:text-xl mb-8 text-gray-300">
            Discover how Athena can revolutionize your learning experience. Join
            us today and start creating customized flashcards effortlessly.
          </p>
          <button
            onClick={() => loginWithRedirect()}
            className="bg-gradient-to-r from-indigo-600 via-purple-600 to-pink-500 text-white px-8 py-3 rounded-full text-lg font-semibold hover:opacity-90 transition-all duration-300 transform hover:scale-105 flex items-center justify-center mx-auto"
          >
            Try Athena Now
            <ArrowRight className="ml-2" size={20} />
          </button>
        </div>
      </div>

      {/* Decorative elements */}
      <div className="absolute inset-0 bg-grid-white/[0.05] bg-[length:20px_20px] md:bg-[length:30px_30px] z-10" />
      <div className="absolute bottom-0 left-0 right-0 h-32 bg-gradient-to-t from-black to-transparent z-10" />
    </section>
  );
};

export default FinalCTA;
