import React, { useState, useCallback, useEffect } from "react";
import { supabase } from "../../supabaseClient";
import DataCollectionToggle from "./DataCollectionToggle";
import UsernameInput from "./UsernameInput";
import SubscriptionPlan from "./SubscriptionPlan";
import DeleteAccountButton from "./DeleteAccountButton";
import SaveSettingsButton from "./SaveSettingsButton";

const SettingsForm = ({ initialData, session }) => {
  const [userSettings, setUserSettings] = useState({
    name: "",
    collect_data: false,
    membership: "free",
  });
  const [hasChanges, setHasChanges] = useState(false);

  useEffect(() => {
    if (initialData) {
      setUserSettings({
        name: initialData.name || "",
        collect_data: initialData.collect_data || false,
        membership: initialData.membership || "free",
      });
    }
  }, [initialData]);

  const handleSettingChange = useCallback((key, value) => {
    setUserSettings((prevSettings) => {
      if (prevSettings[key] === value) return prevSettings;
      setHasChanges(true);
      return { ...prevSettings, [key]: value };
    });
  }, []);

  const handleSaveSettings = async () => {
    if (!hasChanges) return;

    try {
      const { data, error } = await supabase
        .from("users")
        .update(userSettings)
        .eq("id", session.user.id);

      if (error) throw error;

      setHasChanges(false);
    } catch (error) {
      console.error("Error saving settings:", error.message);
    }
  };

  const handleDeleteAccount = async () => {
    // Implement account deletion logic here
    console.log("Delete account");
  };

  return (
    <div className="space-y-8">
      <DataCollectionToggle
        collectData={userSettings.collect_data}
        onToggle={(value) => handleSettingChange("collect_data", value)}
      />
      <UsernameInput
        username={userSettings.name}
        onChange={(value) => handleSettingChange("name", value)}
      />
      {/* <SubscriptionPlan
        currentPlan={userSettings.membership}
        onChangeSubscription={() => {
        }}
      /> */}
      <DeleteAccountButton onDelete={handleDeleteAccount} />
      <SaveSettingsButton isEnabled={hasChanges} onSave={handleSaveSettings} />
    </div>
  );
};

export default SettingsForm;
