import React, { useRef, useEffect } from "react";
import SimpleModal from "../../components/SimpleModal";
import Markdown from "react-markdown";
import Button from "../../components/Button";
import { FileText, Tag } from "lucide-react";
import LoadingSpinner from "../../components/GenerateCards/LoadingSpinner";

const GeneratedPageModal = ({ isOpen, setIsOpen, page, onSave, isLoading }) => {
  const contentRef = useRef(null);

  const scrollToSection = (id) => {
    const section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    if (isOpen && contentRef.current) {
      // Reset scroll position when modal opens
      contentRef.current.scrollTop = 0;
    }
  }, [isOpen]);

  return (
    <SimpleModal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      actionButtons={[
        <Button onClick={onSave} disabled={isLoading}>
          Save
        </Button>,
      ]}
    >
      {isLoading ? (
        <div className="flex justify-center items-center h-64">
          <LoadingSpinner />
        </div>
      ) : !page || !page.content ? (
        <p className="text-zinc-400">No content generated yet.</p>
      ) : (
        <div className="flex">
          <div className="flex-1 overflow-y-auto pr-4" ref={contentRef}>
            <Markdown>{page.content}</Markdown>
            {page.tags && (
              <div id="tags" className="mt-4">
                <h3 className="text-lg font-semibold mb-2">Tags:</h3>
                <div className="flex flex-wrap gap-2">
                  {page.tags.map((tag, index) => (
                    <span
                      key={index}
                      className="bg-gray-200 text-gray-800 px-2 py-1 rounded-full text-sm"
                    >
                      {tag}
                    </span>
                  ))}
                </div>
              </div>
            )}
          </div>
          <div className="w-48 pl-4 border-l border-gray-300 dark:border-gray-700 sticky top-0 h-screen overflow-y-auto">
            <h3 className="text-lg font-semibold mb-4">Table of Contents</h3>
            <div className="space-y-4 text-sm">
              <div className="flex items-center">
                <FileText className="text-gray-500 mr-2" size={16} />
                <button
                  className="text-blue-500 hover:text-blue-700 transition-colors duration-200"
                  onClick={() => scrollToSection("title")}
                >
                  Title
                </button>
              </div>
              {page.tags && (
                <div className="flex items-center">
                  <Tag className="text-gray-500 mr-2" size={16} />
                  <button
                    className="text-blue-500 hover:text-blue-700 transition-colors duration-200"
                    onClick={() => scrollToSection("tags")}
                  >
                    Tags
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </SimpleModal>
  );
};

export default GeneratedPageModal;
