import React from "react";
import { Menu, MessageCircle } from "lucide-react";
import Flashcard from "../../components/Flashcard";
import { SavedAlert } from "../../components/Alert";
import { NoItems, NoDecksAvailable } from "../../components/NoCards";

const MainContent = ({
  isDecksSidebarCollapsed,
  toggleDecksSidebar,
  toggleChatSidebar,
  showSuccess,
  setShowSuccess,
  decks,
  selectedDeck,
  setSelectedDeck,
  setCurrentFlashcard,
  setShowCreateDeckModal,
}) => {
  return (
    <div
      className={`flex-1 bg-zinc-900 border border-zinc-700 rounded-lg flex flex-col items-center justify-start overflow-hidden z-4 ${
        isDecksSidebarCollapsed ? "md:ml-2" : "md:ml-2"
      }`}
    >
      <div className="w-full flex items-center justify-between p-4 md:p-6 bg-zinc-800 md:hidden">
        <h1 className="text-lg font-bold text-zinc-100">Practice</h1>
        <div className="flex items-center space-x-2">
          <button
            onClick={toggleDecksSidebar}
            className="p-2 bg-zinc-700 text-zinc-100 rounded-lg hover:bg-zinc-600 transition-colors duration-200"
          >
            <Menu className="w-5 h-5" />
          </button>
          <button
            onClick={toggleChatSidebar}
            className="p-2 bg-zinc-700 text-zinc-100 rounded-lg hover:bg-zinc-600 transition-colors duration-200"
          >
            <MessageCircle className="w-5 h-5" />
          </button>
        </div>
      </div>
      <div className="flex max-w-4xl w-full px-4 md:px-6 mt-4">
        {showSuccess && (
          <SavedAlert message={"Changes saved"} setShow={setShowSuccess} />
        )}
        {decks.length > 0 ? (
          selectedDeck ? (
            selectedDeck.cards.length > 0 ? (
              <Flashcard
                setCurrentFlashcard={setCurrentFlashcard}
                selectedDeck={selectedDeck}
                setSelectedDeck={setSelectedDeck}
              />
            ) : (
              <div className="flex items-center justify-center mt-16 w-full">
                <NoItems />
              </div>
            )
          ) : (
            <div className="flex items-center justify-center w-full">
              <p className="text-zinc-300 text-xl">Please select a deck</p>
            </div>
          )
        ) : (
          <div className="flex items-center justify-center w-full">
            <NoDecksAvailable
              decks={decks}
              setShowCreateDeckModal={setShowCreateDeckModal}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default MainContent;
