// Fake data for FlashcardGenerator

// Sample decks
const sampleDecks = [
  {
    id: "deck1",
    name: "Basic Mathematics",
    cards: [],
    user_id: "user123",
    origin_owner_id: "user123",
    tags: ["math", "basics"],
    created_at: "2023-08-15T10:30:00Z",
  },
  {
    id: "deck2",
    name: "World Geography",
    cards: [],
    user_id: "user123",
    origin_owner_id: "user123",
    tags: ["geography", "world"],
    created_at: "2023-08-16T14:45:00Z",
  },
];

// Sample flashcards
const sampleFlashcards = [
  {
    id: "card1",
    question: "What is the capital of France?",
    answer: "Paris",
    deck_id: "deck2",
  },
  {
    id: "card2",
    question: "What is 2 + 2?",
    answer: "4",
    deck_id: "deck1",
  },
  {
    id: "card3",
    question: 'Who wrote "Romeo and Juliet"?',
    answer: "William Shakespeare",
    deck_id: null, // Not yet added to a deck
  },
  {
    id: "card4",
    question: "What is the largest planet in our solar system?",
    answer: "Jupiter",
    deck_id: null,
  },
  {
    id: "card5",
    question: "What is the chemical symbol for gold?",
    answer: "Au",
    deck_id: null,
  },
];

// Sample user
const sampleUser = {
  sub: "user123",
  name: "John Doe",
  email: "john.doe@example.com",
};

// Sample input content
const sampleInputContent = {
  topic: "The Solar System",
  pdf: "sample-pdf-content.pdf",
  text: `The solar system consists of the Sun and everything that orbits around it. This includes eight planets, numerous moons, asteroids, comets, and other celestial bodies. The four inner planets (Mercury, Venus, Earth, and Mars) are called terrestrial planets because they have solid, rocky surfaces. The four outer planets (Jupiter, Saturn, Uranus, and Neptune) are called gas giants because they are much larger and are composed mainly of hydrogen and helium.`,
};

export { sampleDecks, sampleFlashcards, sampleUser, sampleInputContent };
