import React, { useState, useEffect } from "react";
import { ChevronRight } from "lucide-react";
import DocumentsSidebar from "./DocumentsSidebar";
import DocumentEditor from "./DocumentEditor";
import { v4 as uuidv4 } from "uuid";

const DocumentPage = ({ session }) => {
  const [documents, setDocuments] = useState([]);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 640);
  const api_host = process.env.REACT_APP_API_HOST;

  useEffect(() => {
    const fetchDocuments = async () => {
      if (!session?.user?.id) return;

      try {
        const response = await fetch(
          `${api_host}/api/get-pages/${session.user.id}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch documents");
        }

        const result = await response.json();
        if (result.success) {
          setDocuments(result.data);
          console.log("result.data", result.data);
        } else {
          console.error(result.message);
        }
      } catch (error) {
        console.error("Error fetching documents:", error);
      }
    };

    fetchDocuments();
  }, [session]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 640);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const filteredDocuments = documents.filter((doc) =>
    doc.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleDocumentSelect = (doc) => {
    setSelectedDocument(doc);
  };

  const handleCreate = async () => {
    const newDoc = {
      id: uuidv4(),
      title: "New Document",
      content: "",
      user_id: session.user.id,
    };
    try {
      const response = await fetch(`${api_host}/api/save-page`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
        body: JSON.stringify(newDoc),
      });

      if (!response.ok) {
        throw new Error("Failed to create document");
      }

      const result = await response.json();
      if (result.success) {
        setDocuments([...documents, result.data[0]]);
        setSelectedDocument(result.data[0]);
      } else {
        console.error(result.message);
      }
    } catch (error) {
      console.error("Error creating document:", error);
    }
  };

  const handleSave = async (updatedDoc) => {
    try {
      const response = await fetch(`${api_host}/api/save-page`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
        body: JSON.stringify(updatedDoc),
      });

      if (!response.ok) {
        throw new Error("Failed to update document");
      }

      const result = await response.json();
      if (result.success) {
        const updatedDocs = documents.map((doc) =>
          doc.id === updatedDoc.id ? result.data[0] : doc
        );
        setDocuments(updatedDocs);
        setSelectedDocument(result.data[0]);
      } else {
        console.error(result.message);
      }
    } catch (error) {
      console.error("Error updating document:", error);
    }
  };

  const handleDelete = async (docId) => {
    try {
      const response = await fetch(`${api_host}/api/delete-page/${docId}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });

      if (!response.ok) {
        throw new Error("Failed to delete document");
      }

      const result = await response.json();
      if (result.success) {
        const updatedDocs = documents.filter((doc) => doc.id !== docId);
        setDocuments(updatedDocs);
        setSelectedDocument(null);
      } else {
        console.error(result.message);
      }
    } catch (error) {
      console.error("Error deleting document:", error);
    }
  };

  const toggleSidebar = () => {
    setIsSidebarCollapsed(!isSidebarCollapsed);
  };

  return (
    <div className="flex h-[calc(100vh-140px)] bg-zinc-900 text-zinc-100">
      <DocumentsSidebar
        documents={filteredDocuments}
        selectedDocument={selectedDocument}
        setSelectedDocument={handleDocumentSelect}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        handleCreate={handleCreate}
        isSidebarCollapsed={isSidebarCollapsed}
        toggleSidebar={toggleSidebar}
        isMobile={isMobile}
      />

      <div className="flex flex-col flex-1 overflow-hidden border border-zinc-700 rounded-lg">
        {isMobile && isSidebarCollapsed && (
          <button
            onClick={toggleSidebar}
            className="p-2 m-2 hover:bg-zinc-600 bg-zinc-700 border border-zinc-600 rounded-lg transition-colors duration-200 self-start"
          >
            <ChevronRight className="w-4 h-4 text-zinc-300" />
          </button>
        )}
        <DocumentEditor
          document={selectedDocument}
          onSave={handleSave}
          onDelete={handleDelete}
        />
      </div>
    </div>
  );
};

export default DocumentPage;
