import React, { useState } from "react";

const Tooltip = ({ tooltipText, children }) => {
  const [showTooltip, setShowTooltip] = useState(false);

  return (
    <div
      className="relative inline-block text-left"
      onMouseEnter={() => setShowTooltip(true)}
      onMouseLeave={() => setShowTooltip(false)}
    >
      {children}
      {showTooltip && (
        <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 w-max px-3 py-1 text-sm text-white bg-zinc-800 rounded shadow-lg z-50">
          {tooltipText}
        </div>
      )}
    </div>
  );
};

export default Tooltip;
