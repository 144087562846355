import React, { useState } from "react";
import {
  BookOpen,
  Upload,
  MessageSquare,
  BrainCog,
  Share2,
  PlusCircle,
  Zap,
  CheckCircle,
} from "lucide-react";
import { useNavigate } from "react-router-dom";
import FeaturesImage from "../../images/features_image.jpg"; // Adjust the import path as needed

const Features = () => {
  const [activeFeature, setActiveFeature] = useState(0);
  const navigate = useNavigate();

  const features = [
    {
      icon: BookOpen,
      title: "Tailored Flashcards",
      description:
        "Experience learning like never before with flashcards uniquely crafted to suit your needs.",
    },
    {
      icon: Upload,
      title: "Transform Documents",
      description:
        "Upload PDFs or long texts and convert them into concise, powerful flashcards instantly.",
    },
    {
      icon: PlusCircle,
      title: "Generate by Prompts",
      description:
        "Create flashcards with just a few prompts, focusing on what's important to you.",
    },
    {
      icon: MessageSquare,
      title: "Personal AI Assistant",
      description:
        "Explore topics in depth with your interactive AI assistant, gaining insights as you chat.",
    },
    {
      icon: Zap,
      title: "Smart Suggestions",
      description:
        "Keep learning with follow-up questions suggested by Athena, delving deeper into subjects.",
    },
    {
      icon: BrainCog,
      title: "Adaptive Learning",
      description:
        "Enjoy an intuitive interface that adapts to your progress, making learning seamless.",
    },
    {
      icon: CheckCircle,
      title: "Custom Quizzes",
      description:
        "Test your knowledge with personalized quizzes tailored to your learning needs.",
    },
    {
      icon: Share2,
      title: "Collaborate and Share",
      description:
        "Share decks, explore community content, and learn together with Athena.",
    },
  ];

  return (
    <section
      id="features"
      className="py-12 md:py-20 bg-gradient-to-b from-gray-900 to-black text-white relative overflow-hidden"
    >
      {/* Background image */}
      <div className="absolute inset-0 z-0">
        <img
          src={FeaturesImage}
          alt="Features background"
          className="w-full h-full object-cover object-center opacity-10"
        />
      </div>

      {/* Background elements */}
      <div className="absolute inset-0 bg-grid-white/[0.05] bg-[length:20px_20px] md:bg-[length:30px_30px] z-10" />
      <div className="absolute inset-0 bg-gradient-to-t from-black via-transparent to-transparent z-10" />

      <div className="container mx-auto px-4 relative z-20">
        <h2 className="text-3xl md:text-5xl font-bold text-center mb-6 md:mb-12">
          <span className="text-transparent bg-clip-text bg-gradient-to-r from-indigo-400 via-purple-500 to-pink-500">
            Athena Features: Unlock the Power of Personalized Learning
          </span>
        </h2>
        <p className="text-center text-gray-300 text-lg md:text-xl mb-8 md:mb-16 max-w-3xl mx-auto">
          Discover how Athena's innovative features can transform your learning
          experience and help you achieve your educational goals faster.
        </p>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 md:gap-6 mb-8 md:mb-16">
          {features.map((feature, index) => (
            <div
              key={index}
              className={`bg-gray-800 bg-opacity-50 backdrop-blur-sm rounded-xl p-4 md:p-6 shadow-lg hover:shadow-xl transition-all duration-300 cursor-pointer ${
                activeFeature === index
                  ? "ring-2 ring-purple-500 transform scale-105"
                  : ""
              }`}
              onClick={() => setActiveFeature(index)}
            >
              <feature.icon
                className="text-indigo-400 mb-3 md:mb-4"
                size={28}
              />
              <h3 className="text-lg md:text-xl font-semibold mb-2">
                {feature.title}
              </h3>
              <p className="text-gray-400 text-xs md:text-sm">
                {feature.description}
              </p>
            </div>
          ))}
        </div>
        <div className="bg-gradient-to-r from-indigo-600 via-purple-600 to-pink-500 rounded-2xl p-6 md:p-8 shadow-2xl">
          <h2 className="text-2xl md:text-3xl font-bold mb-4 md:mb-6 text-center">
            Dive Deeper into {features[activeFeature].title}
          </h2>
          <p className="text-base md:text-lg mb-6 text-center max-w-3xl mx-auto">
            {features[activeFeature].description} Athena adapts to your learning
            style, ensuring that each interaction helps you grasp concepts
            effectively and efficiently.
          </p>
          <div className="mt-6 md:mt-8 text-center">
            <button
              onClick={() => navigate("/login")}
              className="bg-white text-indigo-600 px-6 py-2 md:px-8 md:py-3 rounded-full text-base md:text-lg font-semibold hover:bg-gray-100 transition-all duration-300 transform hover:scale-105 shadow-md"
            >
              Try This Feature
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Features;
