import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { useDecks } from "../services/DeckProvider";

export default function GeneralModal({
  isOpen,
  setIsOpen,
  onDelete,
  selectedDeckId,
  setShowSuccess,
  setSelectedDeck,
  children,
}) {
  function close() {
    setIsOpen(false);
  }

  const { decks, setDecks, refreshDecks } = useDecks();

  const handleDelete = async () => {
    const deleteResult = await onDelete(selectedDeckId);
    await setShowSuccess(deleteResult);
    await new Promise((resolve) => setTimeout(resolve, 500)); // Add a delay of 500ms

    // Refresh the decks
    await refreshDecks();

    // After refreshing, update the selected deck
    const updatedDecks = await refreshDecks();
    setDecks(updatedDecks);
    setSelectedDeck(updatedDecks?.length > 0 ? updatedDecks[0] : null);
    close();
  };

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={close}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </Transition.Child>
          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Are you sure that you want to delete the deck?
                  </Dialog.Title>
                  {children}
                  <div className="mt-4">
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-xl mr-2 border border-transparent bg-red-400 px-4 py-2 text-sm font-medium text-black hover:bg-red-500 focus:outline-none focus-visible:ring-2"
                      onClick={handleDelete}
                    >
                      Delete
                    </button>
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-xl border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                      onClick={close}
                    >
                      Cancel
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
