import React, { useState } from "react";
import { ChevronLeft, ChevronRight } from "lucide-react";
import FlashcardImage from "../../images/flashcard.png";
import GenerateByPDF from "../../images/generate_pdf.png";
import Assistant from "../../images/assistant.png";
import Summary from "../../images/summary.png";
import DeckSelection from "../../images/deck_selection.png";
import GenerateTopic from "../../images/generate_topic.png";
import EditDecks from "../../images/edit_decks.png";

const Showcase = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const features = [
    {
      title: "AI-Powered Flashcards",
      description:
        "Create personalized flashcards tailored to your learning style with our advanced AI technology.",
      image: FlashcardImage,
    },
    {
      title: "Intelligent Document Analysis",
      description:
        "Upload documents and let Athena extract key information to generate comprehensive study materials.",
      image: GenerateByPDF,
    },
    {
      title: "Interactive Learning Assistant",
      description:
        "Engage with our AI-powered assistant to dive deeper into topics and get instant answers to your questions.",
      image: Assistant,
    },
    {
      title: "Summarize and Elaborate Flashcards",
      description:
        "Get concise summaries of your flashcards or dive deeper with detailed explanations to enhance your understanding.",
      image: Summary,
    },
    {
      title: "Deck Selection and Creation",
      description:
        "Easily browse, select, and create new flashcard decks to organize your study materials efficiently.",
      image: DeckSelection,
    },
    {
      title: "Generate Cards by Text or Topic",
      description:
        "Simply type in a topic or paste text, and let our AI generate relevant flashcards to jumpstart your learning.",
      image: GenerateTopic,
    },
    {
      title: "Deck Editor",
      description:
        "Customize and refine your flashcard decks with our intuitive deck editor for a personalized learning experience.",
      image: EditDecks,
    },
  ];

  const nextFeature = () => {
    setActiveIndex((prevIndex) => (prevIndex + 1) % features.length);
  };

  const prevFeature = () => {
    setActiveIndex(
      (prevIndex) => (prevIndex - 1 + features.length) % features.length
    );
  };

  return (
    <section className="py-12 md:py-20 bg-gradient-to-b from-gray-900 to-black text-white relative overflow-hidden">
      <div className="absolute inset-0 bg-grid-white/[0.05] bg-[length:20px_20px] md:bg-[length:30px_30px]" />
      <div className="container mx-auto px-4 relative z-10">
        <h2 className="text-3xl md:text-5xl font-bold text-center mb-6 md:mb-12">
          <span className="text-transparent bg-clip-text bg-gradient-to-r from-indigo-400 via-purple-500 to-pink-500">
            Athena in Action
          </span>
        </h2>
        <p className="text-center text-gray-300 text-lg md:text-xl mb-8 md:mb-16 max-w-3xl mx-auto">
          Experience the power of AI-driven learning. See how Athena transforms
          your study process with these key features.
        </p>

        <div className="relative">
          <div className="flex items-center justify-center mb-6 md:mb-8">
            <div className="w-full max-w-md md:max-w-lg lg:max-w-xl xl:max-w-2xl aspect-[4/3] relative overflow-hidden rounded-lg shadow-2xl">
              <img
                src={features[activeIndex].image}
                alt={features[activeIndex].title}
                className="w-full h-full object-cover object-center"
              />
            </div>
          </div>
          <button
            onClick={prevFeature}
            className="absolute top-1/2 left-2 md:left-4 transform -translate-y-1/2 bg-gray-800 bg-opacity-50 hover:bg-opacity-75 text-white rounded-full p-1 md:p-2 transition-all duration-300"
            aria-label="Previous feature"
          >
            <ChevronLeft size={24} />
          </button>
          <button
            onClick={nextFeature}
            className="absolute top-1/2 right-2 md:right-4 transform -translate-y-1/2 bg-gray-800 bg-opacity-50 hover:bg-opacity-75 text-white rounded-full p-1 md:p-2 transition-all duration-300"
            aria-label="Next feature"
          >
            <ChevronRight size={24} />
          </button>
        </div>

        <div className="text-center mt-6 md:mt-8">
          <h3 className="text-xl md:text-2xl font-bold mb-2 md:mb-4">
            {features[activeIndex].title}
          </h3>
          <p className="text-gray-300 text-sm md:text-base max-w-2xl mx-auto">
            {features[activeIndex].description}
          </p>
        </div>

        <div className="flex justify-center mt-6 md:mt-8">
          {features.map((_, index) => (
            <button
              key={index}
              onClick={() => setActiveIndex(index)}
              className={`w-2 h-2 md:w-3 md:h-3 rounded-full mx-1 md:mx-2 transition-all duration-300 ${
                index === activeIndex
                  ? "bg-purple-500 scale-125"
                  : "bg-gray-500 hover:bg-gray-400"
              }`}
              aria-label={`Go to feature ${index + 1}`}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Showcase;
