import React from "react";

export const SavedAlert = ({ title, message, setShow }) => {
  return (
    <div
      role="alert"
      class="rounded-xl border border-gray-100 bg-white p-4 dark:border-gray-800 dark:bg-gray-900"
    >
      <div class="flex items-start gap-4">
        <span class="text-green-600">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="h-6 w-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
        </span>

        <div class="flex-1">
          <strong class="block font-medium text-gray-900 dark:text-white">
            {title ?? "Success"}
          </strong>

          <p class="mt-1 text-sm text-gray-700 dark:text-gray-200">{message}</p>
        </div>

        <button
          onClick={() => setShow(false)}
          class="text-gray-500 transition hover:text-gray-600 dark:text-gray-400 dark:hover:text-gray-500"
        >
          <span class="sr-only">Dismiss popup</span>

          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="h-6 w-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
      </div>
    </div>
  );
};

export const ErrorAlert = ({ title, message, setShowError }) => {
  return (
    <div
      role="alert"
      class="rounded border-s-4 border-red-500 bg-red-50 p-4 dark:border-red-600 dark:bg-red-900"
    >
      <div class="flex items-center gap-2 text-red-800 dark:text-red-100">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="currentColor"
          class="h-5 w-5"
        >
          <path
            fill-rule="evenodd"
            d="M9.401 3.003c1.155-2 4.043-2 5.197 0l7.355 12.748c1.154 2-.29 4.5-2.599 4.5H4.645c-2.309 0-3.752-2.5-2.598-4.5L9.4 3.003zM12 8.25a.75.75 0 01.75.75v3.75a.75.75 0 01-1.5 0V9a.75.75 0 01.75-.75zm0 8.25a.75.75 0 100-1.5.75.75 0 000 1.5z"
            clip-rule="evenodd"
          />
        </svg>

        <strong class="block font-medium">
          {" "}
          {title ?? "Something went wrong"}
        </strong>
      </div>

      <p class="mt-2 text-sm text-red-700 dark:text-red-200">{message}</p>
      <button
        class="text-red-500 transition hover:text-red-600 dark:text-red-400 dark:hover:text-red-500"
        onClick={() => setShowError(false)}
      >
        Close
      </button>
    </div>
  );
};
