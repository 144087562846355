import React, { useEffect } from "react";
import { ChevronRight, MessageCircle } from "lucide-react";
import ChatWindow from "../ChatWindow";

const ChatSidebar = ({
  isChatSidebarOpen,
  toggleChatSidebar,
  currentFlashcard,
}) => {
  return (
    <div
      className={`fixed md:static inset-y-0 right-0 rounded-lg transform ${
        isChatSidebarOpen
          ? "translate-x-0 w-full sm:w-96 md:w-1/3 lg:w-2/5 bg-zinc-800"
          : "translate-x-full md:translate-x-0 md:w-14 bg-zinc-900"
      } transition-all duration-300 ease-in-out z-30 rounded-lg mr-1 flex flex-col h-full`}
      style={{ maxHeight: "calc(100vh - 4rem)" }}
    >
      <div
        className={`p-4 ${
          isChatSidebarOpen ? "bg-zinc-800" : "bg-zinc-900"
        } rounded-lg flex justify-between items-center flex-shrink-0`}
      >
        <h2
          className={`text-lg font-bold text-zinc-100 ${
            isChatSidebarOpen ? "" : "hidden"
          }`}
        >
          Chat
        </h2>
        <button
          onClick={toggleChatSidebar}
          className="p-2 hover:bg-zinc-700 bg-zinc-800 border border-zinc-700 rounded-lg transition-colors duration-200"
        >
          {isChatSidebarOpen ? (
            <ChevronRight className="w-5 h-5 text-zinc-300" />
          ) : (
            <MessageCircle className="w-5 h-5 text-zinc-300" />
          )}
        </button>
      </div>
      {isChatSidebarOpen && (
        <div className="flex-1 overflow-hidden">
          <ChatWindow
            currentFlashcard={currentFlashcard}
            toggleChatSidebar={toggleChatSidebar}
          />
        </div>
      )}
    </div>
  );
};

export default ChatSidebar;
