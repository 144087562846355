import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { useDecks } from "../../services/DeckProvider.js";
import { notifyError } from "../Notification.js";
import { more_cards } from "../../services/apiCalls.js";

import InputSection from "./InputSection";
import FlashcardDisplay from "./FlashcardDisplay";
import LoadingSpinner from "./LoadingSpinner";

import {
  sampleDecks,
  sampleFlashcards,
  sampleUser,
  sampleInputContent,
} from "./Fakedata.js";

const FlashcardGenerator = ({
  selectedDeck,
  setSelectedDeck,
  availableFlashcards,
  setAvailableFlashcards,
  addedFlashcards,
  setAddedFlashcards,
}) => {
  const [inputMethod, setInputMethod] = useState("topic");
  const [inputContent, setInputContent] = useState(sampleInputContent.topic);
  const [language, setLanguage] = useState("english");
  const [isLoading, setIsLoading] = useState(false);
  const [showTour, setShowTour] = useState(true);
  const [error, setError] = useState(null);
  const [isLongText, setIsLongText] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const { user, fetchUserData, decks, access_token, refreshDecks } = useDecks();
  const api_host = process.env.REACT_APP_API_HOST;
  const TEXTLENGTH = 50000;

  const { deckId } = useParams();

  useEffect(() => {
    setIsLongText(inputContent.length > 100);
  }, [inputContent]);

  const handleInputMethodChange = (method) => {
    setInputMethod(method);
    setInputContent(sampleInputContent[method]);
  };

  const handleInputContentChange = (content) => {
    setInputContent(content);
  };

  const handleLanguageChange = (newLanguage) => {
    setLanguage(newLanguage);
  };

  const updateDeck = async (selectedDeck) => {
    const url = `${api_host}/deck/${selectedDeck["id"]}`;
    const header = {
      method: "PUT",
      body: JSON.stringify(selectedDeck),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${access_token}`,
      },
      redirect: "follow",
    };

    try {
      const response = await fetch(url, header);
      const data = await response.json();
    } catch (error) {
      console.error("Error during fetch operation: ", error.message);
    }
  };

  const onGenerate = () => {
    if (isLongText) {
      generateCardsFromText(
        "PLEASE ANSWER IN THE INPUT LANGUAGE" + inputContent
      );
    } else {
      generateFlashcards();
    }
  };

  const generateFlashcards = async () => {
    setIsLoading(true);
    if (!inputContent) {
      notifyError("Please enter a prompt before generating cards");
      setIsLoading(false);
      return;
    }
    const url = `${api_host}/prompt`;
    const header = {
      method: "POST",
      body: JSON.stringify({
        prompt: "PLEASE ANSWER IN THE INPUT LANGUAGE " + inputContent,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${access_token}`,
      },
      redirect: "follow",
    };
    try {
      const response = await fetch(url, header);
      const data = await response.json();
      const newCards = JSON.parse(data.choices[0].message.content).cards;

      // Add unique IDs to new cards
      const newCardsWithIds = newCards.map((card) => ({
        ...card,
        id: uuidv4(),
      }));

      // Combine new cards with existing available and added cards
      setAvailableFlashcards([...availableFlashcards, ...newCardsWithIds]);
    } catch (error) {
      console.error("Error during fetch operation: ", error.message);
    }
    setIsLoading(false);
  };

  const generateCardsFromText = async (text) => {
    if (text.length > TEXTLENGTH) {
      setErrorMessage(
        "Error: The extracted text is too long. Please use a shorter PDF."
      );
      return;
    }
    setIsLoading(true);
    const url = `${api_host}/create-with-pdf`;
    const payload = {
      language: language,
      text: text,
    };
    const header = {
      method: "POST",
      body: JSON.stringify(payload),
      headers: {
        "Content-Type": "application/json",
      },
      redirect: "follow",
    };
    await fetch(url, header)
      .then((response) => response.json())
      .then((data) => {
        let cards = JSON.parse(data["response"]);
        cards = cards.map((card) => {
          card.id = uuidv4();
          return card;
        });
        setIsLoading(false);
        setAvailableFlashcards(cards);
      })
      .catch((error) => {
        setIsLoading(false);
        console.error("Failed to send text to server", error);
      });
  };

  const onMoreCards = () => {
    if (isLongText) {
      moreCardsFromLongText();
    } else {
      moreCardsFromShortText();
    }
  };

  const moreCardsFromShortText = async () => {
    setIsLoading(true);
    const cards = more_cards(selectedDeck["cards"]);
    if (!inputContent) {
      notifyError("Please enter a prompt before generating more cards");
      setIsLoading(false);
      return;
    }
    const preprompt =
      " PLEASE ANSWER IN THE INPUT LANGUAGE.Please don't give me one of the following cards: ";
    const new_prompt = inputContent + preprompt + cards;
    const url = `${api_host}/more-cards`;
    const header = {
      method: "POST",
      body: JSON.stringify({ prompt: new_prompt }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${access_token}`,
      },
      redirect: "follow",
    };
    try {
      const response = await fetch(url, header);
      const data = await response.json();
      const newCards = JSON.parse(data.choices[0].message.content).cards;
      setAvailableFlashcards([...availableFlashcards, ...newCards]);
    } catch (error) {
      console.error("Error during fetch operation: ", error.message);
    }
    setIsLoading(false);
  };

  const moreCardsFromLongText = async () => {
    setIsLoading(true);
    if (inputContent.length > TEXTLENGTH) {
      setErrorMessage(
        "Error: The extracted text is too long. Please use a shorter text."
      );
      setIsLoading(false);
      return;
    }
    if (!inputContent) {
      notifyError(
        "Please upload a PDF or enter text before generating more cards"
      );
      setIsLoading(false);
      return;
    }
    const example_format =
      'example format: {"cards":[{"question":"What is the Roman Empire?","answer":"The Roman Empire was a period of ancient Roman civilization characterized by an autocratic form of government and large territories around the Mediterranean in Europe, Africa, and Asia.","question_type":"Definition"},]}';
    const cards = more_cards([
      ...selectedDeck.cards,
      ...availableFlashcards,
      ...addedFlashcards,
    ]);
    const new_prompt =
      example_format +
      "in json format and PLEASE ANSWER IN THE INPUT LANGUAGE" +
      inputContent +
      cards;
    const url = `${api_host}/more-cards-pdf`;
    const header = {
      method: "POST",
      body: JSON.stringify({ prompt: new_prompt }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${access_token}`,
      },
      redirect: "follow",
    };
    try {
      const response = await fetch(url, header);
      const data = await response.json();
      let newCards = JSON.parse(data["response"]);
      newCards = newCards.map((card) => ({
        ...card,
        id: uuidv4(),
      }));
      setAvailableFlashcards([...availableFlashcards, ...newCards]);
    } catch (error) {
      console.error("Error during fetch operation: ", error.message);
    }
    setIsLoading(false);
  };

  const saveFlashcards = async () => {
    setIsLoading(true);
    if (!selectedDeck || addedFlashcards.length === 0) return;

    const newDeck = {
      ...selectedDeck,
      cards: [...selectedDeck.cards, ...addedFlashcards],
    };
    setSelectedDeck(newDeck);

    setAddedFlashcards([]);

    await updateDeck(newDeck);

    await refreshDecks(newDeck);
    setAddedFlashcards([]);
    setIsLoading(false);
  };

  const handleEditFlashcard = (editedCard) => {
    const updateCardInArray = (array) =>
      array.map((card) => (card.id === editedCard.id ? editedCard : card));

    if (availableFlashcards.some((card) => card.id === editedCard.id)) {
      setAvailableFlashcards(updateCardInArray(availableFlashcards));
    } else if (addedFlashcards.some((card) => card.id === editedCard.id)) {
      setAddedFlashcards(updateCardInArray(addedFlashcards));
    }
  };

  const handleAddFlashcard = (flashcard) => {
    setAddedFlashcards([...addedFlashcards, flashcard]);
    setAvailableFlashcards(
      availableFlashcards.filter((card) => card.id !== flashcard.id)
    );
  };

  const handleRemoveFlashcard = (flashcard) => {
    setAvailableFlashcards([...availableFlashcards, flashcard]);
    setAddedFlashcards(
      addedFlashcards.filter((card) => card.id !== flashcard.id)
    );
  };

  return (
    <div className="flashcard-generator">
      {errorMessage && (
        <div className="text-red-500 mt-2 text-center">{errorMessage}</div>
      )}
      <InputSection
        inputContent={inputContent}
        language={language}
        onInputContentChange={handleInputContentChange}
        onLanguageChange={handleLanguageChange}
        onGenerate={onGenerate}
        onAddMore={onMoreCards}
        onSave={saveFlashcards}
        isGenerateDisabled={!inputContent}
        isAddMoreDisabled={availableFlashcards.length === 0}
        isSaveDisabled={addedFlashcards.length === 0}
        flashcardCount={availableFlashcards.length + addedFlashcards.length}
      />

      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <FlashcardDisplay
          api_host={api_host}
          access_token={access_token}
          availableFlashcards={availableFlashcards}
          setAddedFlashcards={setAddedFlashcards}
          setAvailableFlashcards={setAvailableFlashcards}
          addedFlashcards={addedFlashcards}
          onAddFlashcard={handleAddFlashcard}
          onRemoveFlashcard={handleRemoveFlashcard}
          onEditFlashcard={handleEditFlashcard}
        />
      )}
    </div>
  );
};

export default FlashcardGenerator;
